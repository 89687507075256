import MobileDetect from 'mobile-detect';
export const Device = {
    TABLET: 'tablet',
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
    NONE: ''
};
let uaString = '';
let appVersionString = '';
let uaData = {};
if (typeof navigator !== 'undefined') {
    uaString = (navigator.userAgent || '').toLocaleLowerCase();
    appVersionString = navigator.appVersion || '';
    uaData = navigator && navigator['userAgentData'];
}
export function isiPhone() {
    return uaString.toLowerCase().indexOf('iphone') !== -1;
}
export function isiPad() {
    if (typeof document === 'undefined')
        return false;
    return (uaString.indexOf('ipad') !== -1 ||
        (uaString.indexOf('macintosh') !== -1 && 'ontouchend' in document));
}
export function isiPod() {
    return uaString.toLowerCase().indexOf('ipod') !== -1;
}
export function isiOS() {
    return isiPad() || isiPhone() || isiPod();
}
export function isAndroid() {
    if (uaData && uaData.platform) {
        return uaData.platform.toLowerCase() === 'android';
    }
    return !!uaString.toLowerCase().match(/android/);
}
export function isChrome() {
    return !isEdge() && !!uaString.toLowerCase().match(/chrome/);
}
export function isFirefox() {
    return !!uaString.toLowerCase().match(/firefox/);
}
export function isEdge() {
    return !!uaString.toLowerCase().match(/edge?/);
}
export function isIE10() {
    return uaString.toLowerCase().indexOf('msie 10.0') !== -1;
}
export function isIE11() {
    return uaString.toLowerCase().indexOf('rv:11.0') !== -1;
}
export function isGoogleBot() {
    return !!uaString.toLowerCase().match(/googlebot/);
}
export function isWindows() {
    if (uaData && uaData.platform) {
        return uaData.platform.toLowerCase() === 'windows';
    }
    return uaString.toLowerCase().indexOf('windows') !== -1;
}
export function isMacintosh() {
    if (uaData && uaData.platform) {
        return uaData.platform.toLowerCase() === 'macos';
    }
    return uaString.toLowerCase().indexOf('macintosh') !== -1 && !isiPad();
}
export function getAndroidVersion() {
    if (appVersionString === '')
        return [];
    const v = appVersionString.match(/Android (\d+).(\d+).?(\d+)?;/);
    return (v && [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)]);
}
export function getiOSVersion() {
    if (appVersionString === '')
        return [];
    const v = appVersionString.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return (v && [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)]);
}
export function detectHasHover() {
    if (typeof document === 'undefined')
        return false;
    const html = document.querySelector('html');
    const hasHover = !('ontouchend' in document);
    if (hasHover) {
        html.classList.add('has-hover');
        console.log('has hover');
    }
    else {
        console.log("doesn't have hover");
    }
    return hasHover;
}
export function detectDevice() {
    if (uaString) {
        const mobileDetect = new MobileDetect(uaString);
        if (mobileDetect.tablet()) {
            console.log('mobile detect: tablet');
            return Device.TABLET;
        }
        else if (mobileDetect.mobile()) {
            console.log('mobile detect: mobile');
            return Device.MOBILE;
        }
        else {
            console.log('mobile detect: desktop');
            return Device.DESKTOP;
        }
    }
    return '';
}
export function detectUA() {
    if (typeof document === 'undefined')
        return {};
    const html = document.querySelector('html');
    const detectUAResults = {
        isiOS: isiOS(),
        isAndroid: isAndroid(),
        isIE11: isIE11(),
        isEdge: isEdge(),
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isWindows: isWindows(),
        isMacintosh: isMacintosh(),
        isGoogleBot: isGoogleBot()
    };
    if (detectUAResults.isiOS)
        html.classList.add('is-ios');
    if (detectUAResults.isAndroid)
        html.classList.add('is-android');
    if (detectUAResults.isIE11)
        html.classList.add('is-ie11');
    if (detectUAResults.isEdge)
        html.classList.add('is-edge');
    if (detectUAResults.isFirefox)
        html.classList.add('is-firefox');
    if (detectUAResults.isWindows && detectUAResults.isChrome)
        html.classList.add('is-winchrome');
    if (detectUAResults.isWindows)
        html.classList.add('is-windows');
    if (detectUAResults.isMacintosh)
        html.classList.add('is-macintosh');
    if (detectUAResults.isGoogleBot)
        html.classList.add('is-googlebot');
    return detectUAResults;
}
