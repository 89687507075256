var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const DEBUG_MODE = false;
import { Vector2 } from 'three';
import { Pane } from 'tweakpane';
import { BreakPoint, BREAK_POINT, KVLayout, KV_BG_IMAGE_ASPECT_RATIO, KV_BG_IMAGE_HEIGHT, KV_BG_IMAGE_WIDTH, KV_LAYOUT_ASPECT_RATIO_THRESHOLD, KV_MAX_HEIGHT_ASPECT_RATIO, KV_MIN_HEIGHT_ASPECT_RATIO, KV_SWAP_IMAGES_WAIT_DURATION } from './constants';
import OnScreen from './libs/OnScreen';
import { Cubic, Expo, Linear, SimpleTween } from './libs/SimpleTween';
import { detectDevice, Device } from './utils/device';
import { wait } from './utils/timer';
import kvScenesData from './webGL/data/data';
import KVGL from './webGL/KVGL';
const devie = detectDevice();
const isMobile = devie === Device.MOBILE || devie === Device.TABLET;
const TIMER_INDICATOR_RADIUS = 100;
export default class KV {
    constructor(selector = '.js-kv', innerSelector = '.js-kvInner', bgImageSelector = '.js-kvBgImage', modelNameSelector = '.js-kvModelName', photoCreditSelector = '.js-kvPhotoCredit', sceneIndicatorCurrentSelector = '.js-kvSceneIndicatorCurrent', sceneIndicatorTotalSelector = '.js-kvSceneIndicatorTotal', timerIndicatorSVGSelector = '.js-kvTimerIndicatorSVG') {
        this.width = 0;
        this.height = 0;
        this.pointerPos = new Vector2();
        this.trailerPos = new Vector2();
        this.currentIndex = 0;
        this.nextIndex = 1;
        this.isInited = false;
        this.isActive = false;
        this.isSwapping = false;
        this.breakPoint = '';
        this.layout = '';
        this.resizeTimer = null;
        this.isMobile = isMobile;
        this.timerIndicatorValue = { value: 0 };
        this.sceneIndicatorValue = { value: 0 };
        this.numScenes = 0;
        this.numLoaded = 0;
        this.numTotal = 0;
        this.matchMediaDefault = window.matchMedia(`all and (min-width: ${BREAK_POINT + 1}px)`);
        this.selector = selector;
        this.innerSelector = innerSelector;
        this.bgImageSelector = bgImageSelector;
        this.modelNameSelector = modelNameSelector;
        this.photoCreditSelector = photoCreditSelector;
        this.sceneIndicatorCurrentSelector = sceneIndicatorCurrentSelector;
        this.sceneIndicatorTotalSelector = sceneIndicatorTotalSelector;
        this.timerIndicatorSVGSelector = timerIndicatorSVGSelector;
        this.getElements();
        this.countUpLoaded = this.countUpLoaded.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onResizeMobile = this.onResizeMobile.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.numScenes = kvScenesData.length;
        // 各シーンで画像が2枚ずつ
        // +1はnoiseTexture
        this.numTotal = this.numScenes * 2 + 1;
        this.updateBreakPoint();
        this.updateLayout();
    }
    getElements() {
        this.el = document.querySelector(this.selector);
        this.innerEl = document.querySelector(this.innerSelector);
        this.bgImageEl = document.querySelector(this.bgImageSelector);
        this.modelNameEl = document.querySelector(this.modelNameSelector);
        this.photoCreditEl = document.querySelector(this.photoCreditSelector);
        this.sceneIndicatorCurrentEl = document.querySelector(this.sceneIndicatorCurrentSelector);
        this.sceneIndicatorTotalEl = document.querySelector(this.sceneIndicatorTotalSelector);
        this.timerIndicatorSVGEl = document.querySelector(this.timerIndicatorSVGSelector);
    }
    countUpLoaded() {
        this.numLoaded++;
    }
    setSwapScenesTimer() {
        this.clearSwapScenesTimer();
        this.swapScenesTimer = window.setTimeout(() => {
            this.swapScenes();
        }, KV_SWAP_IMAGES_WAIT_DURATION * 1000);
        const timerIndicatorSVGEl = this.timerIndicatorSVGEl;
        if (!timerIndicatorSVGEl)
            return;
        const offsetAngle = 0;
        const startRad = (offsetAngle / 180) * Math.PI;
        const startX = TIMER_INDICATOR_RADIUS * Math.cos(startRad);
        const startY = TIMER_INDICATOR_RADIUS * Math.sin(startRad);
        this.timerIndicatorValue.value = 0;
        this.timerIndicatorTween = new SimpleTween(this.timerIndicatorValue, {
            value: 1,
            duration: KV_SWAP_IMAGES_WAIT_DURATION,
            ease: Linear.easeNone,
            onUpdate: () => {
                const value = this.timerIndicatorValue.value;
                const isObtuse = value > 0.5 ? 1 : 0;
                const endRad = startRad + value * Math.PI * 2;
                const endX = TIMER_INDICATOR_RADIUS * Math.cos(endRad);
                const endY = TIMER_INDICATOR_RADIUS * Math.sin(endRad);
                const pathD = `M 0,0 L ${startX},${startY} A ${TIMER_INDICATOR_RADIUS} ${TIMER_INDICATOR_RADIUS} ${offsetAngle} ${isObtuse} 1 ${endX},${endY} z`;
                timerIndicatorSVGEl.setAttribute('d', pathD);
            }
        });
    }
    clearSwapScenesTimer() {
        if (this.swapScenesTimer)
            clearTimeout(this.swapScenesTimer);
    }
    tweenSceneIndicator() {
        const sceneIndicatorCurrentEl = this.sceneIndicatorCurrentEl;
        if (!sceneIndicatorCurrentEl)
            return;
        const valueTo = this.currentIndex === 0 ? this.numScenes : this.currentIndex;
        this.sceneIndicatorTween = new SimpleTween(this.sceneIndicatorValue, {
            value: valueTo,
            duration: 1,
            ease: Expo.easeInOut,
            onUpdate: () => {
                const value = this.sceneIndicatorValue.value % this.numScenes;
                sceneIndicatorCurrentEl.style.transform = `translateY(-${value}em)`;
            },
            onComplete: () => {
                this.sceneIndicatorValue.value =
                    this.sceneIndicatorValue.value % this.numScenes;
            }
        });
    }
    // init resize action
    initResize() {
        if (this.isMobile) {
            this.resizeTimer = null;
            window.addEventListener('orientationchange', this.onResizeMobile);
        }
        else {
            window.addEventListener('resize', this.onResize);
        }
    }
    clearResizeTimer() {
        if (this.resizeTimer != null) {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = null;
        }
    }
    onResizeMobile(e) {
        this.clearResizeTimer();
        this.resizeTimer = window.setTimeout(() => {
            this.onResize();
        }, 300);
    }
    _onPointerMove(posX, posY) {
        const toX = Math.min(1, ((posX - this.width * 0.5) / this.width) * 2);
        const toY = Math.min(1, ((this.height * 0.5 - posY) / this.height) * 2);
        new SimpleTween(this.pointerPos, {
            x: toX,
            y: toY,
            duration: 1.6,
            ease: Expo.easeOut,
            onUpdate: () => {
                if (!this.isActive)
                    return;
                this.kvGL.setPointerPos(this.pointerPos);
            }
        });
        new SimpleTween(this.trailerPos, {
            x: toX,
            y: toY,
            duration: 2,
            ease: Cubic.easeOut,
            onUpdate: () => {
                if (!this.isActive)
                    return;
                this.kvGL.setTrailerPos(this.trailerPos);
            }
        });
    }
    onMouseMove(e) {
        this._onPointerMove(e.clientX, e.clientY);
    }
    onTouchMove(e) {
        const touch = e.touches[0];
        this._onPointerMove(touch.clientX, touch.clientY);
    }
    updateBreakPoint() {
        if (this.matchMediaDefault.matches) {
            // default
            this.breakPoint = BreakPoint.DEFAULT;
        }
        else {
            // small
            this.breakPoint = BreakPoint.SMALL;
        }
    }
    updateLayout() {
        if (!this.el)
            return;
        const w = window.innerWidth;
        const h = window.innerHeight;
        const aspectRatio = w / h;
        if (aspectRatio > KV_LAYOUT_ASPECT_RATIO_THRESHOLD) {
            this.layout = KVLayout.LANDSCAPE;
        }
        else {
            this.layout = KVLayout.PORTRAIT;
        }
        if (this.el)
            this.el.setAttribute('data-layout', this.layout);
        console.log({ layout: this.layout });
    }
    onResize() {
        this.updateBreakPoint();
        this.updateLayout();
        // TODO 高さを設定
        const w = window.innerWidth;
        const h = window.innerHeight;
        if (this.el) {
            const minHeight = w / KV_MIN_HEIGHT_ASPECT_RATIO[this.layout];
            const maxHeight = w / KV_MAX_HEIGHT_ASPECT_RATIO[this.layout];
            this.el.style.minHeight = `${minHeight}px`;
            this.el.style.maxHeight = `${maxHeight}px`;
            this.el.style.height = `${h}px`;
            this.width = this.el.offsetWidth;
            this.height = this.el.offsetHeight;
        }
        if (!this.isInited || !this.isActive)
            return;
        this.kvGL.setBreakPoint(this.breakPoint);
        this.kvGL.setLayout(this.layout);
        // bgの設定
        const bgImageEL = this.bgImageEl;
        const bgRect = bgImageEL.getBoundingClientRect();
        const bgWidth = bgRect.width;
        const bgHeight = bgRect.height;
        const bgAspectRatio = bgWidth / bgHeight;
        const bgUVOffset = new Vector2(0, 0);
        const bgUVSize = new Vector2(1, 1);
        let _bgW, _bgH;
        if (bgAspectRatio > KV_BG_IMAGE_ASPECT_RATIO) {
            _bgW = KV_BG_IMAGE_WIDTH;
            _bgH = _bgW / bgAspectRatio;
        }
        else {
            _bgH = KV_BG_IMAGE_HEIGHT;
            _bgW = _bgH * bgAspectRatio;
        }
        bgUVSize.x = (_bgW / KV_BG_IMAGE_WIDTH) * 0.9;
        bgUVSize.y = (_bgH / KV_BG_IMAGE_HEIGHT) * 0.9;
        bgUVOffset.x = (1 - bgUVSize.x) * 0.5;
        bgUVOffset.y = (1 - bgUVSize.y) * 0.5;
        const cornerRadius = parseFloat(window.getComputedStyle(bgImageEL).borderRadius);
        this.kvGL.applyBgParams({
            width: bgRect.width,
            height: bgRect.height,
            offsetY: -bgRect.top - window.scrollY - bgRect.height * 0.5 + this.height * 0.5,
            uvOffset: bgUVOffset,
            uvSize: bgUVSize,
            cornerRadius,
            containerWidth: this.width,
            containerHeight: this.height
        });
        this.kvGL.onResize();
    }
    getLoadedPercent() {
        return Math.floor((this.numLoaded / this.numTotal) * 100);
    }
    pause() {
        var _a;
        if (!this.isInited || !this.isActive)
            return;
        (_a = this.kvGL) === null || _a === void 0 ? void 0 : _a.pause();
        this.clearResizeTimer();
        this.clearSwapScenesTimer();
    }
    resume() {
        if (!this.isInited || !this.isActive)
            return;
        this.kvGL.resume();
        this.setSwapScenesTimer();
    }
    play() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isInited || !this.isActive)
                return;
            this.setSwapScenesTimer();
            (_a = this.el) === null || _a === void 0 ? void 0 : _a.classList.add('is-playing');
            yield this.kvGL.play(0, () => {
                var _a;
                (_a = this.el) === null || _a === void 0 ? void 0 : _a.classList.add('is-noThumb');
            });
            if (!this.isActive)
                return;
        });
    }
    swapScenes() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isInited || !this.isActive)
                return;
            this.setSwapScenesTimer();
            this.currentIndex = this.nextIndex;
            this.nextIndex = (this.currentIndex + 1) % this.numScenes;
            this.tweenSceneIndicator();
            this.isSwapping = true;
            (_a = this.el) === null || _a === void 0 ? void 0 : _a.classList.add('is-swapping');
            yield this.kvGL.swapScenes(this.currentIndex, this.nextIndex, () => {
                var _a, _b;
                if (!this.isActive)
                    return;
                // クレジットや右のタイトル？などを表示
                this.modelNameEl.innerText =
                    kvScenesData[this.currentIndex].modelName;
                this.photoCreditEl.innerText =
                    kvScenesData[this.currentIndex].photoCredit || '';
                this.isSwapping = false;
                (_a = this.el) === null || _a === void 0 ? void 0 : _a.classList.remove('is-swapping');
                (_b = this.el) === null || _b === void 0 ? void 0 : _b.classList.remove('is-noThumb');
            }, () => {
                var _a;
                (_a = this.el) === null || _a === void 0 ? void 0 : _a.classList.add('is-noThumb');
            });
            if (!this.isActive)
                return;
        });
    }
    initSceneIndicator() {
        var _a;
        const totalEl = this.sceneIndicatorTotalEl;
        const currentEl = this.sceneIndicatorCurrentEl;
        totalEl.innerText = this.numScenes.toString();
        const digitItem = currentEl.children[0];
        (_a = digitItem.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(digitItem);
        for (let i = 0; i < this.numScenes * 2; i++) {
            const item = digitItem.cloneNode();
            item.innerText = ((i % this.numScenes) + 1).toString();
            currentEl.appendChild(item);
        }
    }
    activate() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isActive)
                return;
            this.getElements();
            this.initSceneIndicator();
            this.initResize();
            this.modelNameEl.innerText = kvScenesData[0].modelName;
            this.photoCreditEl.innerText =
                kvScenesData[0].photoCredit || '';
            this.onScreen = new OnScreen(this.el, false, (isIntersecting) => {
                if (!this.isInited)
                    return;
                if (isIntersecting) {
                    this.resume();
                }
                else {
                    this.pause();
                }
            });
            this.kvGL.setContainer(this.innerEl);
            this.kvGL.setBreakPoint(this.breakPoint);
            this.kvGL.setLayout(this.layout);
            yield this.kvGL.loadTextures();
            this.isActive = true;
            this.isSwapping = false;
            this.onResize();
            this.kvGL.start();
            this.onScreen.enable();
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('touchmove', this.onTouchMove);
            if (this.isMobile)
                yield wait(0.3);
        });
    }
    deactivate() {
        var _a, _b;
        if (!this.isActive)
            return;
        this.isActive = false;
        this.pause();
        this.currentIndex = 0;
        this.nextIndex = 0;
        this.kvGL.reset();
        this.onScreen.dispose();
        this.el = null;
        this.innerEl = null;
        this.bgImageEl = null;
        this.modelNameEl = null;
        this.photoCreditEl = null;
        (_a = this.timerIndicatorTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.timerIndicatorTween = null;
        (_b = this.sceneIndicatorTween) === null || _b === void 0 ? void 0 : _b.kill();
        this.sceneIndicatorTween = null;
        this.clearSwapScenesTimer();
        this.clearResizeTimer();
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.onResizeMobile);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('touchmove', this.onTouchMove);
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.kvGL = new KVGL(this.innerEl, null, this.countUpLoaded);
            this.kvGL.setBreakPoint(this.breakPoint);
            this.kvGL.setLayout(this.layout);
            yield this.kvGL.init();
            this.isInited = true;
            this.onResize();
            // await this.activate();
            if (DEBUG_MODE)
                this.initDebugPanel();
        });
    }
    initDebugPanel() {
        this.tweakPane = new Pane();
        this.kvGL.initDebugPanel(this.tweakPane);
        const el = this.tweakPane.element.parentElement;
        el.style.zIndex = '10000';
        let isActive = true;
        window.addEventListener('keydown', (e) => {
            if (e.key.toLowerCase() !== 'd')
                return;
            if (isActive) {
                isActive = false;
                el.style.display = 'none';
            }
            else {
                isActive = true;
                el.style.display = 'block';
            }
        });
    }
}
