var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Group, Vector2 } from 'three';
import { wait } from '../utils/timer';
import KVGlitchImage from './KVGlitchImage';
import KVMainImage from './KVMainImage';
import KVMainImageTrailer from './KVMainImageTrailer';
import WebGLBase from './WebGLBase';
export default class KVScene extends Group {
    constructor(kvSceneData, commonPlaneGeometry, mainImageNoiseTexture, countUpLoaded) {
        super();
        this.textureSize = '';
        this.layout = '';
        this.pointerPos = new Vector2();
        this.trailerPos = new Vector2();
        this.kvSceneData = kvSceneData;
        this.countUpLoaded = countUpLoaded;
        this.glitchImage1 = new KVGlitchImage(commonPlaneGeometry);
        const glitchImage1Data = kvSceneData
            .glitchImagesData[0];
        this.glitchImage1.setUVOffset(glitchImage1Data.uvOffset);
        this.glitchImage1.setUVSize(glitchImage1Data.uvSize);
        this.add(this.glitchImage1);
        this.glitchImage2 = new KVGlitchImage(commonPlaneGeometry);
        const glitchImage2Data = kvSceneData
            .glitchImagesData[1];
        this.glitchImage2.setUVOffset(glitchImage2Data.uvOffset);
        this.glitchImage2.setUVSize(glitchImage2Data.uvSize);
        this.add(this.glitchImage2);
        this.glitchImage3 = new KVGlitchImage(commonPlaneGeometry);
        const glitchImage3Data = kvSceneData
            .glitchImagesData[2];
        this.glitchImage3.setUVOffset(glitchImage3Data.uvOffset);
        this.glitchImage3.setUVSize(glitchImage3Data.uvSize);
        this.add(this.glitchImage3);
        this.mainImage = new KVMainImage(commonPlaneGeometry, mainImageNoiseTexture);
        const mainImageData = kvSceneData.mainImageData;
        this.mainImage.setUVOffset(mainImageData.uvOffset);
        this.mainImage.setUVSize(mainImageData.uvSize);
        this.mainImage.initShoeEffect(mainImageData.shoeEffect);
        this.mainImage.renderOrder = 5;
        this.add(this.mainImage);
        this.mainImageTrailer = new KVMainImageTrailer(commonPlaneGeometry);
        this.mainImageTrailer.setUVOffset(mainImageData.uvOffset);
        this.mainImageTrailer.setUVSize(mainImageData.uvSize);
        this.mainImageTrailer.renderOrder = 4;
        this.add(this.mainImageTrailer);
        this.mainImageTrailer.visible = false;
        this.matrixAutoUpdate = false;
    }
    setVisible(mainImageVisible, glitchImagesVisible) {
        this.mainImage.visible = mainImageVisible;
        this.mainImageTrailer.visible =
            mainImageVisible && this.mainImageTrailer.getAnimationValue() > 0;
        this.glitchImage1.visible = glitchImagesVisible;
        this.glitchImage2.visible = glitchImagesVisible;
        this.glitchImage3.visible = glitchImagesVisible;
    }
    // textureSizeとlayoutはupdateされた上で実行する
    loadTexture(textureKey, imageSrcKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const texturesData = this.kvSceneData.textureData[textureKey][this.textureSize];
            if (texturesData.texture)
                return Promise.resolve(texturesData.texture);
            const imgSrc = texturesData[imageSrcKey] || texturesData.src;
            const texture = (yield WebGLBase.initImgTexture(imgSrc));
            texturesData.texture = texture;
            this.countUpLoaded();
            return texture;
        });
    }
    setMainImageNoiseTexture(noiseTexture) {
        var _a;
        (_a = this.mainImage) === null || _a === void 0 ? void 0 : _a.setNoiseTexture(noiseTexture);
    }
    loadTextures(textureSize, imageSrcKey) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setTextureSize(textureSize);
            return Promise.all([
                this.loadTexture('bg', imageSrcKey),
                this.loadTexture('mainSprites', imageSrcKey).then((texture) => {
                    this.mainImage.setTexture(texture);
                    this.mainImageTrailer.setTexture(texture);
                    this.glitchImage1.setTexture(texture);
                    this.glitchImage2.setTexture(texture);
                    this.glitchImage3.setTexture(texture);
                })
            ]);
            // bgのtexture適用はKVGLで
        });
    }
    setMainImageParams(containerWidth, containerHeight, pixelRatio) {
        const mainImageData = this.kvSceneData.mainImageData;
        const mainImageLayoutData = mainImageData[this.layout];
        const mainImageWidth = containerWidth * mainImageLayoutData.width;
        const mainImageHeight = mainImageWidth / mainImageData.aspectRatio;
        const offsetX = mainImageWidth * mainImageLayoutData.offset.x;
        const offsetY = mainImageHeight * mainImageLayoutData.offset.y;
        const rotation = (mainImageLayoutData.angle / 180) * Math.PI;
        this.mainImage.applyParams(mainImageWidth, mainImageHeight, offsetX, offsetY, rotation, containerWidth, containerHeight, mainImageData.effectCenter);
        this.mainImageTrailer.applyParams(mainImageWidth, mainImageHeight, offsetX, offsetY, rotation, containerWidth, containerHeight, pixelRatio);
    }
    setBgGroupTexture(bgGroupTexture) {
        this.mainImageTrailer.setBgGroupTexture(bgGroupTexture);
    }
    setGlitchImagesParams(bgParams, containerWidth, containerHeight) {
        this.setGlitchImageLayout(this.glitchImage1, this.kvSceneData.glitchImagesData[0], bgParams, containerWidth, containerHeight);
        this.setGlitchImageLayout(this.glitchImage2, this.kvSceneData.glitchImagesData[1], bgParams, containerWidth, containerHeight);
        this.setGlitchImageLayout(this.glitchImage3, this.kvSceneData.glitchImagesData[2], bgParams, containerWidth, containerHeight);
    }
    setGlitchImageLayout(glitchImage, glitchImageData, bgParams, containerWidth, containerHeight) {
        const bgWidth = bgParams.width;
        const bgHeight = bgParams.height;
        const bgWidthHalf = bgWidth * 0.5;
        const bgHeightHalf = bgParams.height * 0.5;
        const glitchImageLayoutData = glitchImageData[this.layout];
        let offsetX = 0;
        if (glitchImageLayoutData.basisX === 'left') {
            // left
            offsetX = -bgWidthHalf + bgWidth * glitchImageLayoutData.offset.x;
        }
        else if (glitchImageLayoutData.basisX === 'right') {
            // right
            offsetX = bgWidthHalf + bgWidth * glitchImageLayoutData.offset.x;
        }
        else {
            // center
            offsetX = bgWidth * glitchImageLayoutData.offset.x;
        }
        let offsetY = 0;
        if (glitchImageLayoutData.basisY === 'top') {
            // top
            offsetY = bgHeightHalf + bgHeight * glitchImageLayoutData.offset.y;
        }
        else if (glitchImageLayoutData.basisY === 'bottom') {
            // bottom
            offsetY = -bgHeightHalf + bgHeight * glitchImageLayoutData.offset.y;
        }
        else {
            // center
            offsetY = bgHeight * glitchImageLayoutData.offset.y;
        }
        const glithImageWidth = bgWidth * glitchImageLayoutData.width;
        const glithImageHeight = glithImageWidth / glitchImageData.aspectRatio;
        glitchImage.applyParams(glithImageWidth, glithImageHeight, offsetX, offsetY, containerWidth, containerHeight, glitchImageLayoutData.renderOrder || 0);
        glitchImage.updatePos();
    }
    update(time) {
        this.mainImage.update(time);
        this.mainImageTrailer.update(time);
        this.glitchImage1.update(time);
        this.glitchImage2.update(time);
        this.glitchImage3.update(time);
    }
    setTextureSize(textureSize) {
        this.textureSize = textureSize;
    }
    setLayout(layout) {
        this.layout = layout;
    }
    setPointerPos(pointerPos) {
        this.pointerPos = pointerPos;
        this.mainImage.setPointerPos(this.pointerPos);
        this.glitchImage1.setPointerPos(this.pointerPos);
        this.glitchImage2.setPointerPos(this.pointerPos);
        this.glitchImage3.setPointerPos(this.pointerPos);
    }
    setTrailerPos(trailerPos) {
        this.trailerPos = trailerPos;
        this.mainImageTrailer.setPointerPos(this.trailerPos);
    }
    show(delay = 0, glitchImagesDelay = 2.6, onShowMainImage = () => { }, onHideGlitchImages = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            this.mainImage.show(delay).then(() => {
                onShowMainImage();
            });
            this.mainImageTrailer.show(delay + 2.4);
            return this.showGlitchImages(delay + glitchImagesDelay, onHideGlitchImages);
        });
    }
    hide(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.mainImageTrailer.hide(delay);
            yield this.mainImage.hide(delay);
        });
    }
    initDebugPanel(pane) {
        this.tweakPaneFolder = pane.addFolder({ title: 'scene' });
        this.mainImage.initDebugPanel(this.tweakPaneFolder);
        this.glitchImage1.initDebugPanel(this.tweakPaneFolder);
        this.glitchImage2.initDebugPanel(this.tweakPaneFolder);
        this.glitchImage3.initDebugPanel(this.tweakPaneFolder);
    }
    reset() {
        this.mainImage.reset();
        this.mainImageTrailer.reset();
        this.glitchImage1.reset();
        this.glitchImage2.reset();
        this.glitchImage3.reset();
    }
    showGlitchImages(delay = 0, onHideGlitchImages = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            this.glitchImage1.show(delay);
            this.glitchImage2.show(delay + 0.2);
            this.glitchImage3.show(delay + 0.4);
            yield wait(delay + 0.4 + 2);
            onHideGlitchImages();
            this.glitchImage1.hide();
            this.glitchImage2.hide(0.1);
            return this.glitchImage3.hide(0.2);
        });
    }
}
