var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wait } from '../kv/utils/timer';
import LoadingPercent from './LoadingPercent';
export default class Loading {
    constructor(loopCallback = () => { }, selector = '.js-loading', percentSelector = '.js-loadingPercent', percentDigit1Selector100 = '.js-loadingPercentDigit100', percentDigit1Selector10 = '.js-loadingPercentDigit10', percentDigit1Selector1 = '.js-loadingPercentDigit1') {
        this.loopCallback = () => { };
        this.el = document.body.querySelector(selector);
        this.percent = new LoadingPercent(this.el.querySelector(percentSelector), percentDigit1Selector100, percentDigit1Selector10, percentDigit1Selector1);
        this.update = this.update.bind(this);
        this.loopCallback = loopCallback;
    }
    fadeOut() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield wait(0.8);
            cancelAnimationFrame(this.animationId);
            this.el.setAttribute('data-is-loaded', 'true');
            yield wait(0.2);
            (_a = this.el.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.el);
        });
    }
    start() {
        this.update();
    }
    update() {
        this.animationId = requestAnimationFrame(this.update);
        this.loopCallback();
    }
    setPercent(percentValue = 0) {
        this.percent.setPercent(percentValue);
    }
}
