export const BREAK_POINT = 1100;
export const KV_IMAGE_DIR = '/assets/images/index/kv';
export const KV_BG_IMAGE_WIDTH = 2220;
export const KV_BG_IMAGE_HEIGHT = 1480;
export const KV_BG_IMAGE_ASPECT_RATIO = KV_BG_IMAGE_WIDTH / KV_BG_IMAGE_HEIGHT;
export const KV_SP_LAYOUT_WIDTH_BASIS = 750;
export const KV_PC_LAYOUT_WIDTH_BASIS = 1440;
export const KV_GLITCH_IAMGE_1_ASPECT_RATIO = 220 / 160;
export const KV_GLITCH_IAMGE_2_ASPECT_RATIO = 180 / 220;
export const KV_GLITCH_IAMGE_3_ASPECT_RATIO = 180 / 180;
export const KV_GLITCH_IAMGE_UV_PADDING_X = 0.4;
export const KV_GLITCH_IAMGE_UV_PADDING_Y = 0.1;
export const KV_SHOE_EFFECT_UV_PADDING_X = 0.2;
export const KV_SHOE_EFFECT_UV_PADDING_Y = 0.2;
export const KV_SWAP_IMAGES_WAIT_DURATION = 8;
export const KV_TEXTURE_SIZE_DEFAULT = 4096;
export const KV_MAIN_IMAGE_NOISE_TEXTURE = `${KV_IMAGE_DIR}/noise.jpg`;
// これよりウィンドウサイズ(=KVのコンテナサイズ)の縦横比が大きい場合はlandscape
export const KV_LAYOUT_ASPECT_RATIO_THRESHOLD = 1;
export const KV_MIN_HEIGHT_ASPECT_RATIO = {
    portrait: 3 / 4,
    landscape: 6 / 3
};
export const KV_MAX_HEIGHT_ASPECT_RATIO = {
    portrait: 9 / 16,
    landscape: 4 / 3
};
export const BreakPoint = {
    DEFAULT: 'default',
    SMALL: 'small'
};
export const KVLayout = {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape'
};
export const KVTextureSize = {
    DEFAULT: 'default',
    SMALL: 'small'
};
