var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { WebGL1Renderer, PerspectiveCamera, Color, Scene, TextureLoader, Mesh, Material, Vector2, LinearFilter, LinearMipMapLinearFilter, ClampToEdgeWrapping } from 'three';
import WebGL from 'three/examples/jsm/capabilities/WebGL';
export default class WebGLBase {
    constructor(container, canvas = null) {
        this.pixelRatio = 1;
        this.cameraPosZ = 0;
        this.width = 0;
        this.height = 0;
        this.startTime = 0;
        this.beforeTime = 0;
        this.time = 0;
        this.timeDelta = 0;
        this.downPixelRatioThresholdPixels = 5000000;
        this.maxPixelRatio = Infinity;
        this.isRunning = false;
        this.animationId = 0;
        this.isDisposed = false;
        this.isInited = false;
        this.container = container;
        this.canvas = canvas;
    }
    static get isWebGLAvailable() {
        // return false; // for debug
        if (this.isCheckedWebGLAvailable)
            return this._isWebGLAvailable;
        this._isWebGLAvailable = WebGL.isWebGLAvailable();
        this.isCheckedWebGLAvailable = true;
        return this._isWebGLAvailable;
    }
    static initImgTexture(imgSrc, options = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                const loader = new TextureLoader();
                const defaultOptions = {
                    wrapS: ClampToEdgeWrapping,
                    wrapT: ClampToEdgeWrapping,
                    minFilter: LinearMipMapLinearFilter,
                    magFilter: LinearFilter,
                    generateMipmaps: true
                };
                if (options !== null) {
                    options = Object.assign({}, defaultOptions, options);
                }
                else {
                    options = Object.assign({}, defaultOptions);
                }
                const opt = options;
                loader.load(imgSrc, (texture) => {
                    texture.generateMipmaps = opt.generateMipmaps;
                    texture.wrapS = opt.wrapS;
                    texture.wrapT = opt.wrapT;
                    texture.minFilter = opt.minFilter;
                    texture.magFilter = opt.magFilter;
                    texture.needsUpdate = true;
                    resolve(texture);
                });
            });
        });
    }
    static getUV(width, height, textureWidth, textureHeight, posX = '', posY = '', viewPortScale = 1) {
        const aspectRatio = width / height;
        const textureAspectRatio = textureWidth / textureHeight;
        const uvSize = new Vector2(viewPortScale, viewPortScale);
        const uvOffset = new Vector2(0, 0);
        let scale = 1;
        if (aspectRatio > textureAspectRatio) {
            scale = width / textureWidth;
            uvSize.y = (height / textureHeight / scale) * viewPortScale;
        }
        else {
            scale = height / textureHeight;
            uvSize.x = (width / textureWidth / scale) * viewPortScale;
        }
        if (posY === 'top') {
            uvOffset.y = 0;
        }
        else if (posY === 'bottom') {
            uvOffset.y = 1 - uvSize.y;
        }
        else {
            uvOffset.y = (1 - uvSize.y) * 0.5;
        }
        if (posX === 'left') {
            uvOffset.x = 0;
        }
        else if (posX === 'right') {
            uvOffset.x = 1 - uvSize.x;
        }
        else {
            uvOffset.x = (1 - uvSize.x) * 0.5;
        }
        return { uvSize, uvOffset };
    }
    static disposeObject3D(object, disposeChildren = true, disposeGeometry = true, disposeMaterial = true, removeSelf = true) {
        if (disposeChildren && object.children && object.children.length > 0) {
            let child;
            while ((child = object.children[0])) {
                this.disposeObject3D(child, disposeGeometry, disposeMaterial, removeSelf);
                object.remove(child);
            }
        }
        if (removeSelf && object.parent)
            object.parent.remove(object);
        if (!(object instanceof Mesh))
            return;
        if (disposeGeometry)
            object.geometry.dispose();
        if (disposeMaterial && object.material instanceof Material) {
            object.material.dispose();
        }
    }
    getContainer() {
        return this.container;
    }
    getCanvas() {
        return this.canvas;
    }
    getWidth() {
        return this.width;
    }
    getHeight() {
        return this.height;
    }
    getScene() {
        return this.scene;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!WebGLBase.isWebGLAvailable)
                throw Error('not support webgl');
            yield this.initWebGL();
            this.isInited = true;
        });
    }
    setPixelRatio(pixelRatio, render = false) {
        if (!this.renderer)
            return;
        this.pixelRatio = pixelRatio;
        this.renderer.setPixelRatio(this.pixelRatio);
        if (render)
            this.onResize();
    }
    initWebGL() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            console.log('init webgl');
            this.width = ((_a = this.container) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 2;
            this.height = ((_b = this.container) === null || _b === void 0 ? void 0 : _b.offsetHeight) || 2;
            // renderer
            this.initRenderer();
            // camera
            this.initCamera();
            // scene
            this.scene = new Scene();
            // this.scene.matrixAutoUpdate = false
            // this.scene.matrixWorldNeedsUpdate = true
            this.update = this.update.bind(this);
            yield this.initContents();
            console.log('init contents');
        });
    }
    initContents() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    initDebugPanel(pane) { }
    initRenderer() {
        var _a;
        this.pixelRatio = window.devicePixelRatio;
        const params = {
            // antialias: true,
            alpha: true
        };
        if (this.canvas)
            params.canvas = this.canvas;
        this.renderer = new WebGL1Renderer(params);
        this.renderer.setPixelRatio(this.pixelRatio);
        this.renderer.setClearColor(new Color(0, 0, 0), 0);
        if (!this.canvas) {
            this.canvas = this.renderer.domElement;
            (_a = this.container) === null || _a === void 0 ? void 0 : _a.appendChild(this.canvas);
        }
    }
    initCamera() {
        this.camera = new PerspectiveCamera(45, 1, 0.1, 1000000);
        this.cameraPosZ = 500;
        this.camera.position.z = this.cameraPosZ;
        this.camera.lookAt(0, 0, 0);
    }
    update() {
        this.animationId = requestAnimationFrame(this.update);
        this.render();
    }
    start() {
        if (!this.isInited)
            return;
        console.log('start webgl');
        this.stop();
        this.isRunning = true;
        this.startTimeUpdate();
        this.update();
    }
    startTimeUpdate() {
        this.startTime = new Date().getTime();
        this.beforeTime = this.startTime;
    }
    stop() {
        if (!this.isInited)
            return;
        this.isRunning = false;
        if (this.animationId)
            cancelAnimationFrame(this.animationId);
    }
    render() {
        if (!this.isInited || this.startTime === 0)
            return;
        const currentTime = new Date().getTime();
        this.timeDelta = currentTime - this.beforeTime;
        this.beforeTime = currentTime;
        this.time = currentTime - this.startTime;
        this.beforeRenderContents();
        this.renderer.render(this.scene, this.camera);
        this.afterRenderContents();
    }
    beforeRenderContents() { }
    afterRenderContents() { }
    onResize(callback = null, width = 0, height = 0) {
        if (!this.isInited)
            return;
        this.width = width || this.container.offsetWidth || 1;
        this.height = height || this.container.offsetHeight || 1;
        this.updateCamera();
        this.renderer.setSize(this.width, this.height);
        this.onResizeContents();
        if (callback)
            callback();
        this.render();
    }
    onResizeContents() { }
    updateCamera() {
        const camera = this.camera;
        camera.aspect = this.width / this.height;
        this.cameraPosZ =
            this.height / 2 / Math.tan((camera.fov * Math.PI) / 180 / 2);
        camera.position.z = this.cameraPosZ;
        camera.far = this.cameraPosZ * 2;
        camera.updateMatrix();
        camera.updateProjectionMatrix();
    }
    adjustPixelDeviceRatio() {
        const devidcePixelRatio = window.devicePixelRatio;
        const p = this.width * this.height * devidcePixelRatio * devidcePixelRatio;
        this.setPixelRatio(Math.min(this.maxPixelRatio, p > this.downPixelRatioThresholdPixels
            ? Math.max(1, devidcePixelRatio * 0.5)
            : devidcePixelRatio));
        console.log('devidePixerlRatio: ', this.pixelRatio, p);
    }
    dispose() {
        if (this.isDisposed)
            return;
        this.stop();
        if (this.renderer) {
            this.renderer.clear();
            this.renderer.dispose();
            if (this.renderer.info.programs) {
                for (const program of this.renderer.info.programs) {
                    program.destroy();
                }
            }
            const gl = this.renderer.getContext();
            const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
            for (let unit = 0; unit < numTextureUnits; ++unit) {
                gl.activeTexture(gl.TEXTURE0 + unit);
                gl.bindTexture(gl.TEXTURE_2D, null);
                gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
            }
            gl.bindBuffer(gl.ARRAY_BUFFER, null);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
            gl.bindRenderbuffer(gl.RENDERBUFFER, null);
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            const buf = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, buf);
            const numAttributes = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
            for (let attrib = 0; attrib < numAttributes; ++attrib) {
                gl.vertexAttribPointer(attrib, 1, gl.FLOAT, false, 0, 0);
            }
            gl.canvas.width = 1;
            gl.canvas.height = 1;
            this.renderer.forceContextLoss();
        }
        delete this.scene;
        delete this.camera;
        delete this.renderer;
        this.isDisposed = true;
        this.isInited = false;
    }
}
WebGLBase.isCheckedWebGLAvailable = false;
WebGLBase._isWebGLAvailable = false;
