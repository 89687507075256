import { LinearFilter, Mesh, RawShaderMaterial, Vector2, WebGLRenderTarget } from 'three';
import vertexShader from './glsl/kvBgGroupPlane.vert';
import fragmentShader from './glsl/kvBgGroupPlane.frag';
export default class KVBgGroupPlane extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                resolution: { value: new Vector2(1, 1) },
                texture: { value: null }
            }
        }));
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
        this.renderTarget = new WebGLRenderTarget(2, 2, {
            minFilter: LinearFilter,
            // minFilter: LinearMipMapLinearFilter,
            magFilter: LinearFilter,
            generateMipmaps: false
        });
        this.renderTarget.texture.needsUpdate = true;
        this.material.uniforms.texture.value =
            this.renderTarget.texture;
    }
    getRenderTargetTexture() {
        return this.renderTarget.texture;
    }
    render(renderer, scene, camera) {
        renderer.setRenderTarget(this.renderTarget);
        renderer.render(scene, camera);
        renderer.setRenderTarget(null);
    }
    setResolution(width, height, pixelRatio) {
        const material = this.material;
        const w = width * pixelRatio;
        const h = height * pixelRatio;
        material.uniforms.resolution.value.x = w;
        material.uniforms.resolution.value.y = h;
        this.renderTarget.setSize(w, h);
    }
}
