import { Vector2 } from 'three';
import { KV_IMAGE_DIR, KV_PC_LAYOUT_WIDTH_BASIS, KV_SP_LAYOUT_WIDTH_BASIS } from '../../constants';
import mainSprites1RawData from './mainSprites1.json';
import mainSprites2RawData from './mainSprites2.json';
import mainSprites3RawData from './mainSprites3.json';
// kvScenesData
const kvScenesData = [
    // 1
    {
        modelName: 'Toki Model',
        textureData: {
            mainSprites: {
                small: {
                    src: `${KV_IMAGE_DIR}/mainSprites1S.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites1S.webp`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/mainSprites1.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites1.webp`,
                    loadPromise: null,
                    texture: null
                }
            },
            bg: {
                small: {
                    src: `${KV_IMAGE_DIR}/bg1S.jpg`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/bg1.jpg`,
                    loadPromise: null,
                    texture: null
                }
            }
        },
        mainImageData: {
            uvOffset: new Vector2(),
            uvSize: new Vector2(),
            aspectRatio: 1,
            effectCenter: new Vector2(1302 / 1834, 1612 / 2470),
            shoeEffect: {
                offset: new Vector2(359 / 1834, -376 / 2470),
                scale: new Vector2(),
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                uvRectRatio: new Vector2()
            },
            portrait: {
                width: 712 / KV_SP_LAYOUT_WIDTH_BASIS,
                angle: 1,
                offset: new Vector2((712 / 2 - 357) / 712, -(949 / 2 - 523) / 949),
                renderOrder: 3
            },
            landscape: {
                width: 917 / KV_PC_LAYOUT_WIDTH_BASIS,
                angle: 62.11,
                offset: new Vector2((917 / 2 - 457) / 917, -(1235 / 2 - 731) / 1235),
                renderOrder: 3
            }
        },
        glitchImagesData: [
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 200 / 580,
                    offset: new Vector2(-75 / 580, 114 / 820),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 1
                },
                landscape: {
                    width: 220 / 1100,
                    offset: new Vector2(-260 / 1100, -148 / 700),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 1
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(25 / 580, -82 / 820),
                    basisX: 'left',
                    basisY: 'center',
                    renderOrder: 2
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(241 / 1100, 80 / 700),
                    basisX: 'left',
                    basisY: 'bottom',
                    renderOrder: 2
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(100 / 580, 132 / 820),
                    basisX: 'center',
                    basisY: 'bottom',
                    renderOrder: 0
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(-120 / 1100, -15 / 700),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 0
                }
            }
        ]
    },
    // 2
    {
        modelName: 'Sumatran Tiger Model',
        textureData: {
            mainSprites: {
                small: {
                    src: `${KV_IMAGE_DIR}/mainSprites2S.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites2S.webp`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/mainSprites2.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites2.webp`,
                    loadPromise: null,
                    texture: null
                }
            },
            bg: {
                small: {
                    src: `${KV_IMAGE_DIR}/bg2S.jpg`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/bg2.jpg`,
                    loadPromise: null,
                    texture: null
                }
            }
        },
        mainImageData: {
            uvOffset: new Vector2(),
            uvSize: new Vector2(),
            aspectRatio: 1,
            effectCenter: new Vector2(977 / 1438, 1112 / 2118),
            shoeEffect: {
                offset: new Vector2(242 / 1438, -115 / 2118),
                scale: new Vector2(),
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                uvRectRatio: new Vector2()
            },
            portrait: {
                width: 608 / KV_SP_LAYOUT_WIDTH_BASIS,
                angle: -32.52,
                offset: new Vector2((608 / 2 - 325) / 608, -(896 / 2 - 480) / 896),
                renderOrder: 3
            },
            landscape: {
                width: 719 / KV_PC_LAYOUT_WIDTH_BASIS,
                angle: -61.38,
                offset: new Vector2((719 / 2 - 423) / 719, -(1059 / 2 - 490) / 1059),
                renderOrder: 1
            }
        },
        glitchImagesData: [
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 200 / 580,
                    offset: new Vector2(-74 / 580, -146 / 820),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 2
                },
                landscape: {
                    width: 220 / 1100,
                    offset: new Vector2(-141 / 1100, 46 / 700),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 3
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(-84 / 580, 118 / 820),
                    basisX: 'center',
                    basisY: 'bottom',
                    renderOrder: 1
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(-247 / 1100, 140 / 700),
                    basisX: 'right',
                    basisY: 'bottom',
                    renderOrder: 2
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(27 / 580, -27 / 820),
                    basisX: 'left',
                    basisY: 'center',
                    renderOrder: 0
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(183 / 1100, 40 / 700),
                    basisX: 'left',
                    basisY: 'center',
                    renderOrder: 0
                }
            }
        ]
    },
    // 3
    {
        modelName: 'Starry Night Toad Model',
        photoCredit: 'Photo by Beto_Rueda / Adapted.',
        textureData: {
            mainSprites: {
                small: {
                    src: `${KV_IMAGE_DIR}/mainSprites3S.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites3S.webp`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/mainSprites3.png`,
                    webpSrc: `${KV_IMAGE_DIR}/mainSprites3.webp`,
                    loadPromise: null,
                    texture: null
                }
            },
            bg: {
                small: {
                    src: `${KV_IMAGE_DIR}/bg3S.jpg`,
                    loadPromise: null,
                    texture: null
                },
                default: {
                    src: `${KV_IMAGE_DIR}/bg3.jpg`,
                    loadPromise: null,
                    texture: null
                }
            }
        },
        mainImageData: {
            uvOffset: new Vector2(),
            uvSize: new Vector2(),
            aspectRatio: 1,
            effectCenter: new Vector2(1099 / 1936, 1497 / 1956),
            shoeEffect: {
                offset: new Vector2(137 / 1936, -528 / 1956),
                scale: new Vector2(),
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                uvRectRatio: new Vector2()
            },
            portrait: {
                width: 736 / KV_SP_LAYOUT_WIDTH_BASIS,
                angle: -42.34,
                offset: new Vector2((736 / 2 - 424) / KV_SP_LAYOUT_WIDTH_BASIS, -(744 / 2 - 370) / KV_SP_LAYOUT_WIDTH_BASIS),
                renderOrder: 3
            },
            landscape: {
                width: 968 / KV_PC_LAYOUT_WIDTH_BASIS,
                angle: 17.9,
                offset: new Vector2((968 / 2 - 517) / KV_PC_LAYOUT_WIDTH_BASIS, -(978 / 2 - 462) / KV_PC_LAYOUT_WIDTH_BASIS),
                renderOrder: 2
            }
        },
        glitchImagesData: [
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 200 / 580,
                    offset: new Vector2(-142 / 580, -146 / 820),
                    basisX: 'right',
                    basisY: 'top',
                    renderOrder: 0
                },
                landscape: {
                    width: 220 / 1100,
                    offset: new Vector2(-160 / 1100, 153 / 700),
                    basisX: 'right',
                    basisY: 'center',
                    renderOrder: 3
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(28 / 580, -100 / 820),
                    basisX: 'left',
                    basisY: 'center',
                    renderOrder: 1
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(68 / 1100, -172 / 700),
                    basisX: 'left',
                    basisY: 'center',
                    renderOrder: 1
                }
            },
            {
                uvOffset: new Vector2(),
                uvSize: new Vector2(),
                aspectRatio: 1,
                portrait: {
                    width: 160 / 580,
                    offset: new Vector2(-71 / 580, 146 / 820),
                    basisX: 'right',
                    basisY: 'bottom',
                    renderOrder: 2
                },
                landscape: {
                    width: 180 / 1100,
                    offset: new Vector2(-142 / 1100, 162 / 700),
                    basisX: 'right',
                    basisY: 'bottom',
                    renderOrder: 0
                }
            }
        ]
    }
];
const getJSONFrameData = (frameData, spritesWidth, spritesHeight) => {
    const w = frameData.frame.w;
    const h = frameData.frame.h;
    return {
        aspectRatio: w / h,
        uvSize: new Vector2(w / spritesWidth, h / spritesHeight),
        uvOffset: new Vector2(frameData.frame.x / spritesWidth, frameData.frame.y / spritesHeight)
    };
};
const getMainImageShoeEffectData = (frameData, offset, mainImageWidth, mainImageHeight, spritesWidth, spritesHeight) => {
    const w = frameData.frame.w;
    const h = frameData.frame.h;
    const resMin = Math.min(w, h);
    return {
        offset,
        scale: new Vector2(w / mainImageWidth, h / mainImageHeight),
        uvSize: new Vector2(w / spritesWidth, h / spritesHeight),
        uvOffset: new Vector2(frameData.frame.x / spritesWidth, frameData.frame.y / spritesHeight),
        uvRectRatio: new Vector2(resMin / w, resMin / h)
    };
};
const insertMainSpritesRawData = (sceneData, jsonData) => {
    const spritesWidth = jsonData.meta.size.w;
    const spritesHeight = jsonData.meta.size.h;
    const mainImageFrameData = jsonData.frames['main.png'];
    sceneData.mainImageData = Object.assign(Object.assign(Object.assign({}, sceneData.mainImageData), getJSONFrameData(mainImageFrameData, spritesWidth, spritesHeight)), { shoeEffect: getMainImageShoeEffectData(jsonData.frames['shoeEffect.png'], sceneData.mainImageData.shoeEffect.offset, mainImageFrameData.frame.w, mainImageFrameData.frame.h, spritesWidth, spritesHeight) });
    sceneData.glitchImagesData[0] = Object.assign(Object.assign({}, sceneData.glitchImagesData[0]), getJSONFrameData(jsonData.frames['glitch1.png'], spritesWidth, spritesHeight));
    sceneData.glitchImagesData[1] = Object.assign(Object.assign({}, sceneData.glitchImagesData[1]), getJSONFrameData(jsonData.frames['glitch2.png'], spritesWidth, spritesHeight));
    sceneData.glitchImagesData[2] = Object.assign(Object.assign({}, sceneData.glitchImagesData[2]), getJSONFrameData(jsonData.frames['glitch3.png'], spritesWidth, spritesHeight));
};
insertMainSpritesRawData(kvScenesData[0], mainSprites1RawData);
insertMainSpritesRawData(kvScenesData[1], mainSprites2RawData);
insertMainSpritesRawData(kvScenesData[2], mainSprites3RawData);
export default kvScenesData;
