import LoadingPercentDigit from './LoadingPercentDigit';
export default class LoadingPercent {
    constructor(el, percentDigit1Selector100 = '.js-loadingPercentDigit100', percentDigit1Selector10 = '.js-loadingPercentDigit10', percentDigit1Selector1 = '.js-loadingPercentDigit1') {
        this.el = el;
        this.digit100 = new LoadingPercentDigit(this.el.querySelector(percentDigit1Selector100), 2); // 0 or 1
        this.digit10 = new LoadingPercentDigit(this.el.querySelector(percentDigit1Selector10));
        this.digit1 = new LoadingPercentDigit(this.el.querySelector(percentDigit1Selector1));
    }
    setPercent(percentValue = 0) {
        this.digit100.setValue(Math.floor(percentValue / 100));
        this.digit10.setValue(Math.floor(percentValue / 10));
        this.digit1.setValue(percentValue);
    }
}
