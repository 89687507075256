import { Vector2, Mesh, RawShaderMaterial } from 'three';
import vertexShader from './glsl/bgRedCircle.vert';
import fragmentShader from './glsl/bgRedCircle.frag';
import { Linear, SimpleTween } from '../../kv/libs/SimpleTween';
import { COMMON_PLANE_GEOMETRY_SIZE } from './BgRedCirclesGL';
export const BgRedCirclePos = {
    LEFT: 'left',
    TOP: 'top'
};
export default class BgRedCircle extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                scrollY: { value: 0 },
                offsetY: { value: 0 },
                time: { value: 0 },
                alpha: { value: 0 },
                resolution: { value: new Vector2(1, 1) }
            }
        }));
        this.breakPoint = '';
        this.circlePos = BgRedCirclePos.LEFT;
        this.scrollY = { value: 0 };
        this.isActive = false;
        this.offsetTop = 0;
        this.elHeight = 0;
        this.size = 0;
        this.containerWidth = 0;
        this.containerHeight = 0;
        // this.frustumCulled = false;
        this.matrixAutoUpdate = false;
    }
    activate(selector, context = null) {
        const wrapper = context || document.body;
        this.el = wrapper.querySelector(selector) || document.body;
        this.circlePos =
            this.el.getAttribute('data-bg-red-circle-pos') ||
                BgRedCirclePos.LEFT;
        this.visible = true;
        this.isActive = true;
    }
    deactivate() {
        this.isActive = false;
        this.visible = false;
    }
    update(time) {
        if (!this.isActive)
            return;
        const material = this.material;
        material.uniforms.time.value = time;
    }
    updateSize() {
        const material = this.material;
        const scale = this.size / COMMON_PLANE_GEOMETRY_SIZE;
        material.uniforms.resolution.value.x = this.size;
        material.uniforms.resolution.value.y = this.size;
        this.scale.x = scale;
        this.scale.y = scale;
    }
    updatePosition() {
        if (this.circlePos === BgRedCirclePos.LEFT) {
            this.position.x = -this.containerWidth * 0.5;
            this.position.y = 0;
        }
        else if (this.circlePos === BgRedCirclePos.TOP) {
            this.position.x = 0;
            this.position.y = this.containerHeight * 0.5 - this.offsetTop;
        }
    }
    setBreakPoint(breakPoint) {
        this.breakPoint = breakPoint;
    }
    onResize(size, containerWidth, containerHeight) {
        this.size = size;
        this.containerWidth = containerWidth;
        this.containerHeight = containerHeight;
        if (!this.isActive || !this.el)
            return;
        const rect = this.el.getBoundingClientRect();
        this.offsetTop = rect.top + window.scrollY;
        this.elHeight = rect.height;
        this.updatePosition();
        this.updateSize();
        this.updateMatrix();
    }
    show(delay = 0, duration = 0.6) {
        var _a;
        if (!this.isActive)
            return;
        const material = this.material;
        if (duration === 0) {
            (_a = this.alphaTween) === null || _a === void 0 ? void 0 : _a.kill();
            material.uniforms.alpha.value = 1;
            return;
        }
        this.alphaTween = new SimpleTween(material.uniforms.alpha, {
            value: 1,
            duration,
            delay,
            ease: Linear.easeNone
        });
    }
    hide(delay = 0, duration = 0.4) {
        if (!this.isActive)
            return;
        const material = this.material;
        this.alphaTween = new SimpleTween(material.uniforms.alpha, {
            value: 0,
            duration,
            delay,
            ease: Linear.easeNone
        });
    }
    setOffsetY(offsetY) {
        if (!this.isActive)
            return;
        const material = this.material;
        material.uniforms.offsetY.value = offsetY;
    }
}
