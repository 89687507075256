import { Expo, SimpleTween } from '../kv/libs/SimpleTween';
export default class LoadingPercentDigit {
    constructor(el, numDigitItems = 10, digitHeight = 1, digitHeightUnit = 'em') {
        this.valueObj = { value: 0 };
        this.el = el;
        this.numDigitItems = numDigitItems;
        this.setDigitHeight(digitHeight, digitHeightUnit);
    }
    setValue(value = 0) {
        this.valueTween = new SimpleTween(this.valueObj, {
            value,
            duration: 0.4,
            ease: Expo.easeOut,
            onUpdate: () => {
                this.el.style.transform = `translateY(-${(this.valueObj.value % this.numDigitItems) * this.digitHeight}${this.digitHeightUnit})`;
            }
        });
    }
    setDigitHeight(digitHeight = 1, digitHeightUnit = 'em') {
        this.digitHeight = digitHeight;
        this.digitHeightUnit = digitHeightUnit;
    }
}
