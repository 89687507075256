export default class OnScreen {
    constructor(el, isOnceMode = false, intersectionCallback, options = { rootMargin: '0px 0px' }) {
        this.isEnabled = false;
        this.isOnceMode = false;
        this.isIntersecting = false;
        this.intersectionRatio = 0;
        if (el)
            this._el = el;
        this.isOnceMode = isOnceMode;
        this.intersectionCallback = intersectionCallback;
        this.observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry) {
                this.isIntersecting = entry.isIntersecting;
                this.intersectionRatio = entry.intersectionRatio;
                this.onIntersect();
            }
        }, options);
        this.observer.observe(el);
    }
    get el() {
        return this._el;
    }
    enable() {
        this.isEnabled = true;
        if (this.isIntersecting)
            this.onIntersect();
    }
    disable() {
        var _a;
        this.isEnabled = false;
        (_a = this.el) === null || _a === void 0 ? void 0 : _a.setAttribute('data-is-on-screen', 'false');
    }
    dispose() {
        this.disconnect();
    }
    disconnect() {
        this.observer.disconnect();
    }
    onIntersect() {
        var _a, _b;
        if (this.isEnabled) {
            this.intersectionCallback(this.isIntersecting, this.el, this.intersectionRatio);
            if (this.isIntersecting) {
                (_a = this.el) === null || _a === void 0 ? void 0 : _a.setAttribute('data-is-on-screen', 'true');
                if (this.isOnceMode) {
                    this.dispose();
                }
            }
            else {
                (_b = this.el) === null || _b === void 0 ? void 0 : _b.setAttribute('data-is-on-screen', 'false');
            }
        }
    }
}
