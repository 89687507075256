var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function preloadImg(imagePath) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(function (resolve, reject) {
            const img = new Image();
            const onLoad = () => {
                img.removeEventListener('load', onLoad);
                img.removeEventListener('error', onError);
                return resolve(img);
            };
            const onError = () => {
                img.removeEventListener('load', onLoad);
                img.removeEventListener('error', onError);
                return reject(`img load error: ${imagePath}`);
            };
            img.addEventListener('load', onLoad);
            img.addEventListener('error', onError);
            img.src = imagePath;
        });
    });
}
export function detectWebpSupported() {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof document === 'undefined')
            return Promise.resolve(false);
        const html = document.querySelector('html');
        const webpImg = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';
        let resolver;
        const img = new Image();
        const onLoad = () => {
            console.log('webp is supported');
            img.removeEventListener('load', onLoad);
            img.removeEventListener('error', onError);
            resolver(true);
        };
        const onError = () => {
            console.log('webp is not supported');
            html.classList.add('is-webpNotSupported');
            img.removeEventListener('load', onLoad);
            img.removeEventListener('error', onError);
            resolver(false);
        };
        return new Promise((resolve) => {
            resolver = resolve;
            img.addEventListener('load', onLoad, { once: true });
            img.addEventListener('error', onError, { once: true });
            img.src = webpImg;
        });
    });
}
