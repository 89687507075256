var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const DEBUG_MODE = false;
import { Pane } from 'tweakpane';
import { BreakPoint, BREAK_POINT } from '../kv/constants';
import { detectDevice, Device } from '../kv/utils/device';
import BgRedCirclesGL from './webGL/BgRedCirclesGL';
const devie = detectDevice();
const isMobile = devie === Device.MOBILE || devie === Device.TABLET;
export default class BgRedCircles {
    constructor(selector = '.js-bgRedCircle') {
        this.breakPoint = '';
        this.resizeTimer = null;
        this.isMobile = isMobile;
        this.isInited = false;
        this.matchMediaDefault = window.matchMedia(`all and (min-width: ${BREAK_POINT + 1}px)`);
        this.selector = selector;
        this.el = document.body.querySelector(this.selector);
        this.onResize = this.onResize.bind(this);
        this.onResizeMobile = this.onResizeMobile.bind(this);
        this.updateBreakPoint();
        window.addEventListener('scroll', this.onScroll.bind(this));
    }
    // init resize action
    initResize() {
        if (this.isMobile) {
            this.resizeTimer = null;
            window.addEventListener('orientationchange', this.onResizeMobile);
        }
        else {
            window.addEventListener('resize', this.onResize);
        }
    }
    clearResizeTimer() {
        if (this.resizeTimer != null) {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = null;
        }
    }
    onResizeMobile(e) {
        this.clearResizeTimer();
        this.resizeTimer = window.setTimeout(() => {
            this.onResize();
        }, 300);
    }
    updateBreakPoint() {
        if (this.matchMediaDefault.matches) {
            // default
            this.breakPoint = BreakPoint.DEFAULT;
        }
        else {
            // small
            this.breakPoint = BreakPoint.SMALL;
        }
    }
    onResize() {
        this.updateBreakPoint();
        if (!this.isInited)
            return;
        this.bgRedCircleGL.setBreakPoint(this.breakPoint);
        this.bgRedCircleGL.onResize();
        this.onScroll();
    }
    setMode(isIndexPage = true, context = null) {
        this.bgRedCircleGL.setMode(isIndexPage, context);
        this.onResize();
    }
    start() {
        var _a;
        if (!this.isInited)
            return;
        (_a = this.bgRedCircleGL) === null || _a === void 0 ? void 0 : _a.start();
    }
    pause() {
        var _a;
        if (!this.isInited)
            return;
        (_a = this.bgRedCircleGL) === null || _a === void 0 ? void 0 : _a.pause();
        this.clearResizeTimer();
    }
    resume() {
        if (!this.isInited)
            return;
        this.bgRedCircleGL.resume();
    }
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isInited)
                return;
            yield this.bgRedCircleGL.show();
        });
    }
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isInited)
                return;
            yield this.bgRedCircleGL.hide();
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.bgRedCircleGL = new BgRedCirclesGL(this.el, null);
            this.bgRedCircleGL.setBreakPoint(this.breakPoint);
            yield this.bgRedCircleGL.init();
            this.isInited = true;
            this.initResize();
            this.onResize();
            if (DEBUG_MODE)
                this.initDebugPanel();
        });
    }
    initDebugPanel() {
        this.tweakPane = new Pane();
        this.bgRedCircleGL.initDebugPanel(this.tweakPane);
        const el = this.tweakPane.element.parentElement;
        el.style.zIndex = '10000';
        let isActive = true;
        window.addEventListener('keydown', (e) => {
            if (e.key.toLowerCase() !== 'd')
                return;
            if (isActive) {
                isActive = false;
                el.style.display = 'none';
            }
            else {
                isActive = true;
                el.style.display = 'block';
            }
        });
    }
    onScroll() {
        this.bgRedCircleGL.onScroll();
    }
}
