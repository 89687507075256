var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrthographicCamera } from 'three';
import WebGLBase from './WebGLBase';
export default class IsometricGLTemplate extends WebGLBase {
    constructor(container, canvas = null) {
        super(container, canvas);
    }
    initRenderer() {
        super.initRenderer();
    }
    initContents() {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    beforeRenderContents() { }
    afterRenderContents() { }
    onResizeContents() { }
    initCamera() {
        this.camera = new OrthographicCamera(-this.width * 0.5, this.width * 0.5, this.height * 0.5, -this.height * 0.5, 1, 1000);
        this.camera.position.z = 500;
        this.camera.lookAt(0, 0, 0);
        this.camera.matrixAutoUpdate = false;
    }
    updateCamera() {
        const camera = this.camera;
        camera.left = -this.width * 0.5;
        camera.right = this.width * 0.5;
        camera.top = this.height * 0.5;
        camera.bottom = -this.height * 0.5;
        camera.updateMatrix();
        camera.updateProjectionMatrix();
    }
}
