var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mesh, RawShaderMaterial, Vector2 } from 'three';
import vertexShader from './glsl/kvBg.vert';
import fragmentShader from './glsl/kvBg.frag';
import { Expo, Linear, SimpleTween } from '../libs/SimpleTween';
import { COMMON_PLANE_GEOMETRY_SIZE } from './KVGL';
export default class KVBgImage extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                time: { value: 0 },
                animationValue: { value: 0 },
                visibleValue: { value: 0 },
                scaleValue: { value: 0 },
                cornerRadius: { value: 0 },
                resolution: { value: new Vector2(1, 1) },
                uvOffset: { value: new Vector2(0, 0) },
                uvSize: { value: new Vector2(1, 1) },
                pointerPos: { value: new Vector2(0, 0) },
                texture0: { value: null },
                texture1: { value: null }
            }
        }));
        this.animationValueTween = null;
        this.visibileValueTween = null;
        this.scaleValueTween = null;
        this.pointerPos = new Vector2();
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
    }
    setTexture(textureIndex, texture) {
        const material = this.material;
        material.uniforms[`texture${textureIndex}`].value = texture;
    }
    setPointerPos(pointerPos) {
        const material = this.material;
        this.pointerPos = pointerPos;
        material.uniforms.pointerPos.value = pointerPos;
    }
    reset() {
        var _a, _b, _c;
        const material = this.material;
        material.uniforms.animationValue.value = 0;
        material.uniforms.visibleValue.value = 0;
        (_a = this.visibileValueTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.visibileValueTween = null;
        (_b = this.animationValueTween) === null || _b === void 0 ? void 0 : _b.kill();
        this.animationValueTween = null;
        (_c = this.scaleValueTween) === null || _c === void 0 ? void 0 : _c.kill();
        this.scaleValueTween = null;
    }
    resetAnimationValue() {
        const material = this.material;
        material.uniforms.animationValue.value = 0;
    }
    applyParams({ width, height, offsetY, uvOffset, uvSize, cornerRadius, containerWidth, containerHeight }) {
        const material = this.material;
        material.uniforms.resolution.value.x = width;
        material.uniforms.resolution.value.y = height;
        material.uniforms.uvOffset.value = uvOffset;
        material.uniforms.uvSize.value = uvSize;
        material.uniforms.cornerRadius.value = cornerRadius;
        this.scale.set(width / COMMON_PLANE_GEOMETRY_SIZE, height / COMMON_PLANE_GEOMETRY_SIZE, 1);
        this.position.y = offsetY;
        this.updateMatrix();
    }
    swapImages(delay = 0, duration = 1.6, fromValue = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            const material = this.material;
            material.uniforms.animationValue.value = fromValue;
            this.animateAnimationValue({ value: 1, delay, duration });
        });
    }
    play(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.swapImages(delay, 0.8, 0.5);
            this.animateScaleValue({ value: 1, delay });
            this.animateVisibleValue({ value: 1, delay });
        });
    }
    animateVisibleValue({ value = 0, duration = 0.4, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.visibileValueTween = new SimpleTween(material.uniforms.visibleValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    animateAnimationValue({ value = 0, duration = 1.2, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValueTween = new SimpleTween(material.uniforms.animationValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    animateScaleValue({ value = 0, duration = 1.2, delay = 0, onComplete = () => { }, ease = Expo.easeOut }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.scaleValueTween = new SimpleTween(material.uniforms.scaleValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    update(time) {
        const material = this.material;
        material.uniforms.time.value = time;
    }
    initDebugPanel(pane) {
        this.tweakPaneFolder = pane.addFolder({ title: 'bg' });
        const material = this.material;
        const params = {
            animationValue: material.uniforms.animationValue.value
        };
        this.tweakPaneFolder
            .addInput(params, 'animationValue', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.animationValue.value = e.value;
        });
    }
}
