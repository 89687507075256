var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mesh, RawShaderMaterial, Vector2 } from 'three';
import vertexShader from './glsl/kvMainImage.vert';
import fragmentShader from './glsl/kvMainImageTrailer.frag';
import { Linear, SimpleTween } from '../libs/SimpleTween';
import { COMMON_PLANE_GEOMETRY_SIZE } from './KVGL';
export default class KVMainImageTrailer extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                time: { value: 0 },
                animationValue: { value: 0 },
                resolution: { value: new Vector2(1, 1) },
                texture: { value: null },
                bgGroupTexture: { value: null },
                bgGroupResolution: { value: new Vector2() },
                uvOffset: { value: new Vector2() },
                uvSize: { value: new Vector2(1, 1) },
                uvPadding: { value: new Vector2(0, 0) }
            }
        }));
        this.animationValueTween = null;
        this.animationValue1Tween = null;
        this.pointerPos = new Vector2();
        this.pointerMoveSize = new Vector2();
        this.offset = new Vector2();
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
    }
    setTexture(texture) {
        const material = this.material;
        material.uniforms.texture.value = texture;
    }
    setBgGroupTexture(bgGroupTexture) {
        const material = this.material;
        material.uniforms.bgGroupTexture.value = bgGroupTexture;
    }
    setBgGroupResolution(bgGroupResolution) {
        const material = this.material;
        material.uniforms.bgGroupResolution.value = bgGroupResolution;
    }
    setUVSize(uvSize) {
        const material = this.material;
        material.uniforms.uvSize.value = uvSize;
    }
    setUVOffset(uvOffset) {
        const material = this.material;
        material.uniforms.uvOffset.value = uvOffset;
    }
    reset() {
        var _a;
        const material = this.material;
        material.uniforms.animationValue.value = 0;
        (_a = this.animationValueTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.animationValueTween = null;
    }
    applyParams(width, height, offsetX, offsetY, rotation, containerWidth, containerHeight, pixelRatio) {
        const material = this.material;
        material.uniforms.resolution.value.x = width;
        material.uniforms.resolution.value.y = height;
        material.uniforms.bgGroupResolution.value.x = containerWidth * pixelRatio;
        material.uniforms.bgGroupResolution.value.y = containerHeight * pixelRatio;
        this.scale.set(width / COMMON_PLANE_GEOMETRY_SIZE, height / COMMON_PLANE_GEOMETRY_SIZE, 1);
        this.rotation.z = rotation;
        this.offset.x = offsetX;
        this.offset.y = offsetY;
        const pointerMoveRatio = 0.1;
        this.pointerMoveSize.x = containerWidth * pointerMoveRatio;
        this.pointerMoveSize.y = containerHeight * pointerMoveRatio;
        this.updatePos();
    }
    updatePos() {
        this.position.x =
            this.offset.x + this.pointerMoveSize.x * this.pointerPos.x;
        this.position.y =
            this.offset.y + this.pointerMoveSize.y * this.pointerPos.y;
        this.updateMatrix();
    }
    update(time) {
        const material = this.material;
        material.uniforms.time.value = time;
    }
    animateAnimationValue({ value = 0, duration = 0.4, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValueTween = new SimpleTween(material.uniforms.animationValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    getAnimationValue() {
        const material = this.material;
        return material.uniforms.animationValue.value;
    }
    show(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.animateAnimationValue({ value: 1, delay, duration: 0.4 });
        });
    }
    hide(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.animateAnimationValue({ value: 0, delay, duration: 0.4 });
            this.visible = false;
        });
    }
    setPointerPos(pointerPos) {
        this.pointerPos = pointerPos;
        this.updatePos();
    }
}
