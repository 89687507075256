var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AdditiveBlending, Mesh, RawShaderMaterial, Vector2 } from 'three';
import vertexShader from './glsl/kvMainImageShoeEffect.vert';
import fragmentShader from './glsl/kvMainImageShoeEffect.frag';
import { Linear, SimpleTween } from '../libs/SimpleTween';
import { KV_SHOE_EFFECT_UV_PADDING_X, KV_SHOE_EFFECT_UV_PADDING_Y } from '../constants';
import { COMMON_PLANE_GEOMETRY_SIZE } from './KVGL';
export default class KVMainImageShoeEffect extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            blending: AdditiveBlending,
            uniforms: {
                time: { value: 0 },
                animationValue: { value: 0 },
                noiseScale0: { value: 6 },
                noiseUVOffsetRatio0: { value: 0.52 },
                noiseScale1: { value: 1 },
                noiseUVOffsetRatio1: { value: 0.16 },
                noiseUVOffsetRatio2: { value: 0.01 },
                texture: { value: null },
                noiseTexture: { value: null },
                uvOffset: { value: new Vector2() },
                uvRectRatio: { value: new Vector2(1, 1) },
                uvSize: { value: new Vector2(1, 1) },
                uvPadding: {
                    value: new Vector2(KV_SHOE_EFFECT_UV_PADDING_X, KV_SHOE_EFFECT_UV_PADDING_Y)
                }
            }
        }));
        this.animationValueTween = null;
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
        this.renderOrder = 6;
    }
    applyData(data, mainImageNoiseTexture) {
        this.position.x = data.offset.x * COMMON_PLANE_GEOMETRY_SIZE;
        this.position.y = data.offset.y * COMMON_PLANE_GEOMETRY_SIZE;
        this.scale.x = data.scale.x * (1 + KV_SHOE_EFFECT_UV_PADDING_X * 2);
        this.scale.y = data.scale.y * (1 + KV_SHOE_EFFECT_UV_PADDING_Y * 2);
        const material = this.material;
        material.uniforms.uvOffset.value = data.uvOffset;
        material.uniforms.uvSize.value = data.uvSize;
        material.uniforms.uvRectRatio.value = data.uvRectRatio;
        material.uniforms.noiseTexture.value = mainImageNoiseTexture;
    }
    setTexture(texture) {
        const material = this.material;
        material.uniforms.texture.value = texture;
    }
    reset() {
        var _a;
        const material = this.material;
        material.uniforms.animationValue.value = 0;
        (_a = this.animationValueTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.animationValueTween = null;
    }
    setNoiseTexture(noiseTexture) {
        const material = this.material;
        material.uniforms.noiseTexture.value = noiseTexture;
    }
    update(time) {
        const material = this.material;
        material.uniforms.time.value = time;
    }
    animateAnimationValue({ value = 0, duration = 2, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValueTween = new SimpleTween(material.uniforms.animationValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    show(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.animateAnimationValue({
                value: 1,
                delay: delay,
                duration: 2
            });
        });
    }
    initDebugPanel(folder) {
        const material = this.material;
        const params = {
            animationValue: material.uniforms.animationValue.value,
            noiseScale0: material.uniforms.noiseScale0.value,
            noiseUVOffsetRatio0: material.uniforms.noiseUVOffsetRatio0.value,
            noiseScale1: material.uniforms.noiseScale1.value,
            noiseUVOffsetRatio1: material.uniforms.noiseUVOffsetRatio1.value,
            noiseUVOffsetRatio2: material.uniforms.noiseUVOffsetRatio2.value
        };
        this.tweakPaneFolder = folder.addFolder({ title: 'shoeEffect' });
        this.tweakPaneFolder
            .addInput(params, 'animationValue', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.animationValue.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'noiseScale0', { min: 0.1, max: 400 })
            .on('change', (e) => {
            material.uniforms.noiseScale0.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'noiseUVOffsetRatio0', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.noiseUVOffsetRatio0.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'noiseScale1', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.noiseScale1.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'noiseUVOffsetRatio1', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.noiseUVOffsetRatio1.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'noiseUVOffsetRatio2', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.noiseUVOffsetRatio2.value = e.value;
        });
    }
}
