var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PlaneGeometry } from 'three';
import IsometricGLTemplate from '../../kv/webGL/IsometricGLTemplate';
import BgRedCircle from './BgRedCircle';
import { Expo, SimpleTween } from '../../kv/libs/SimpleTween';
export const COMMON_PLANE_GEOMETRY_SIZE = 100;
export default class BgRedCirclesGL extends IsometricGLTemplate {
    constructor(container, canvas = null) {
        super(container, canvas);
        this.breakPoint = '';
    }
    initContents() {
        return __awaiter(this, void 0, void 0, function* () {
            const scene = this.scene;
            this.maxPixelRatio = 0.5;
            this.setPixelRatio(0.5);
            const commonPlaneGeometry = new PlaneGeometry(COMMON_PLANE_GEOMETRY_SIZE, COMMON_PLANE_GEOMETRY_SIZE);
            this.circle0 = new BgRedCircle(commonPlaneGeometry);
            this.circle1 = new BgRedCircle(commonPlaneGeometry);
            scene.add(this.circle0);
            scene.add(this.circle1);
            this.onResize();
        });
    }
    beforeRenderContents() {
        this.circle0.update(this.time);
        this.circle1.update(this.time);
    }
    onResizeContents() {
        // this.adjustPixelDeviceRatio();
        const resMin = Math.min(this.width, this.height);
        const circleSize = resMin * 1.8;
        this.circle0.onResize(circleSize, this.width, this.height);
        this.circle1.onResize(circleSize, this.width, this.height);
    }
    setBreakPoint(breakPoint) {
        var _a, _b;
        this.breakPoint = breakPoint;
        (_a = this.circle0) === null || _a === void 0 ? void 0 : _a.setBreakPoint(breakPoint);
        (_b = this.circle1) === null || _b === void 0 ? void 0 : _b.setBreakPoint(breakPoint);
    }
    resume() {
        this.start();
    }
    pause() {
        this.stop();
    }
    show(delay = 0, duration = 0.6) {
        this.circle0.show(delay, duration);
        this.circle1.show(0, duration);
    }
    hide(delay = 0, duration = 0.4) {
        this.circle0.hide(delay, duration);
        this.circle1.hide(delay, duration);
    }
    initDebugPanel(pane) {
        const folder = pane.addFolder({ title: 'BgRedCircle' });
    }
    onScroll() {
        const camera = this.camera;
        new SimpleTween(camera.position, {
            y: -window.scrollY,
            duration: 0.8,
            ease: Expo.easeOut,
            onUpdate: () => {
                camera.updateMatrix();
            }
        });
    }
    setMode(isIndexPage = true, context = null) {
        if (isIndexPage) {
            this.circle0.activate('.js-redCircle0', context);
            this.circle1.activate('.js-redCircle1', context);
        }
        else {
            this.circle0.activate('.js-redCircle0', context);
            this.circle1.deactivate();
        }
    }
}
