var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mesh, RawShaderMaterial, Vector2 } from 'three';
import vertexShader from './glsl/kvGlitchImage.vert';
import fragmentShader from './glsl/kvGlitchImage.frag';
import { Expo, SimpleTween } from '../libs/SimpleTween';
import { COMMON_PLANE_GEOMETRY_SIZE } from './KVGL';
import { KV_GLITCH_IAMGE_UV_PADDING_X, KV_GLITCH_IAMGE_UV_PADDING_Y } from '../constants';
export default class KVGlitchImage extends Mesh {
    constructor(commonPlaneGeometry) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                time: { value: 0 },
                glitchValue: { value: 1 },
                animationValue: { value: 0 },
                resolution: { value: new Vector2(1, 1) },
                texture: { value: null },
                uvOffset: { value: new Vector2() },
                uvSize: { value: new Vector2(1, 1) },
                uvPadding: {
                    value: new Vector2(KV_GLITCH_IAMGE_UV_PADDING_X, KV_GLITCH_IAMGE_UV_PADDING_Y)
                }
            }
        }));
        this.glitchValueTween = null;
        this.animationValueTween = null;
        this.pointerPos = new Vector2();
        this.pointerMoveSize = new Vector2();
        this.offset = new Vector2();
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
    }
    setTexture(texture) {
        const material = this.material;
        material.uniforms.texture.value = texture;
    }
    setUVSize(uvSize) {
        const material = this.material;
        material.uniforms.uvSize.value = uvSize;
    }
    setUVOffset(uvOffset) {
        const material = this.material;
        material.uniforms.uvOffset.value = uvOffset;
    }
    resetAnimationValue() {
        const material = this.material;
        material.uniforms.animationValue.value = 0;
    }
    applyParams(width, height, offsetX, offsetY, containerWidth, containerHeight, renderOrder) {
        const material = this.material;
        material.uniforms.resolution.value.x = width;
        material.uniforms.resolution.value.y = height;
        // グリッチエフェクトの余裕を持たせるため
        this.scale.set((width / COMMON_PLANE_GEOMETRY_SIZE) *
            (1 + KV_GLITCH_IAMGE_UV_PADDING_X * 2), (height / COMMON_PLANE_GEOMETRY_SIZE) *
            (1 + KV_GLITCH_IAMGE_UV_PADDING_Y * 2), 1);
        this.offset.x = offsetX;
        this.offset.y = offsetY;
        this.renderOrder = renderOrder;
        const pointerMoveRatio = 0.04 + (renderOrder + 1) * 0.002;
        this.pointerMoveSize.x = containerWidth * pointerMoveRatio;
        this.pointerMoveSize.y = containerHeight * pointerMoveRatio;
        this.updateMatrix();
    }
    updatePos() {
        this.position.x =
            this.offset.x + this.pointerMoveSize.x * this.pointerPos.x;
        this.position.y =
            this.offset.y + this.pointerMoveSize.y * this.pointerPos.y;
        this.updateMatrix();
    }
    animateGlitchValue({ value = 0, duration = 0.4, delay = 0, onComplete = () => { }, ease = Expo.easeOut }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.glitchValueTween = new SimpleTween(material.uniforms.glitchValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    animateAnimationValue({ value = 0, duration = 0.4, delay = 0, onComplete = () => { }, ease = Expo.easeOut }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValueTween = new SimpleTween(material.uniforms.animationValue, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    show(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.animateAnimationValue({ value: 1, duration: 0.1, delay });
            return this.animateGlitchValue({
                value: 0.04,
                duration: 0.8,
                delay: delay + 0.2
            });
        });
    }
    hide(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.animateGlitchValue({
                value: 1,
                duration: 0.4,
                delay: delay
            });
            return this.animateAnimationValue({
                value: 0,
                duration: 0.1,
                delay: delay + 0.2
            });
        });
    }
    update(time) {
        const material = this.material;
        material.uniforms.time.value = time;
    }
    initDebugPanel(folder) {
        const material = this.material;
        const params = {
            animationValue: material.uniforms.animationValue.value,
            glitchValue: material.uniforms.glitchValue.value
        };
        this.tweakPaneFolder = folder.addFolder({ title: 'glitchImage' });
        this.tweakPaneFolder.expanded = false;
        this.tweakPaneFolder
            .addInput(params, 'animationValue', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.animationValue.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'glitchValue', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.glitchValue.value = e.value;
        });
    }
    reset() {
        var _a, _b;
        const material = this.material;
        material.uniforms.animationValue.value = 0;
        material.uniforms.glitchValue.value = 1;
        (_a = this.glitchValueTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.glitchValueTween = null;
        (_b = this.animationValueTween) === null || _b === void 0 ? void 0 : _b.kill();
        this.animationValueTween = null;
    }
    setPointerPos(pointerPos) {
        this.pointerPos = pointerPos;
        this.updatePos();
    }
}
