export const Linear = {
    easeNone: (t) => {
        return t;
    },
};
export const Quad = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return c * (t /= d) * t + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return -c * (t /= d) * (t - 2) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d / 2) < 1) {
            return (c / 2) * t * t + b;
        }
        return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
};
export const Cubic = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return c * (t /= d) * t * t + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d / 2) < 1) {
            return (c / 2) * t * t * t + b;
        }
        return (c / 2) * ((t -= 2) * t * t + 2) + b;
    },
};
export const Quart = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return c * (t /= d) * t * t * t + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d / 2) < 1) {
            return (c / 2) * t * t * t * t + b;
        }
        return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
    },
};
export const Quint = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return c * (t /= d) * t * t * t * t + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d / 2) < 1) {
            return (c / 2) * t * t * t * t * t + b;
        }
        return (c / 2) * ((t -= 2) * t * t * t * t + 2) + b;
    },
};
export const Sine = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return c * Math.sin((t / d) * (Math.PI / 2)) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
    },
};
export const Expo = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if (t === 0) {
            return b;
        }
        if (t === d) {
            return b + c;
        }
        if ((t /= d / 2) < 1) {
            return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        }
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
    },
};
export const Circ = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d / 2) < 1) {
            return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
        }
        return (c / 2) * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
    },
};
export const Elastic = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        let s = 1.70158;
        let p = 0;
        let a = c;
        if (t === 0) {
            return b;
        }
        if ((t /= d) === 1) {
            return b + c;
        }
        if (!p) {
            p = d * 0.3;
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        }
        else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p)) + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        let s = 1.70158;
        let p = 0;
        let a = c;
        if (t === 0) {
            return b;
        }
        if ((t /= d) === 1) {
            return b + c;
        }
        if (!p) {
            p = d * 0.3;
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        }
        else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        return a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) + c + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        let s = 1.70158;
        let p = 0;
        let a = c;
        if (t === 0) {
            return b;
        }
        if ((t /= d / 2) === 2) {
            return b + c;
        }
        if (!p) {
            p = d * (0.3 * 1.5);
        }
        if (a < Math.abs(c)) {
            a = c;
            s = p / 4;
        }
        else {
            s = (p / (2 * Math.PI)) * Math.asin(c / a);
        }
        if (t < 1) {
            return (-0.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p)) + b);
        }
        return (a * Math.pow(2, -10 * (t -= 1)) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) * 0.5 + c + b);
    },
};
export const Back = {
    easeIn: (t, b = 0, c = 1, d = 1, s = 1.70158) => {
        return c * (t /= d) * t * ((s + 1) * t - s) + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1, s = 1.70158) => {
        return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
    },
    easeInOut: (t, b = 0, c = 1, d = 1, s = 1.70158) => {
        if ((t /= d / 2) < 1) {
            return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
        }
        return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
    },
};
export const Bounce = {
    easeIn: (t, b = 0, c = 1, d = 1) => {
        return c - Bounce.easeOut(d - t, 0, c, d) + b;
    },
    easeOut: (t, b = 0, c = 1, d = 1) => {
        if ((t /= d) < 1 / 2.75) {
            return c * (7.5625 * t * t) + b;
        }
        else if (t < 2 / 2.75) {
            return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
        }
        else if (t < 2.5 / 2.75) {
            return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
        }
        else {
            return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
        }
    },
    easeInOut: (t, b = 0, c = 1, d = 1) => {
        if (t < d / 2) {
            return Bounce.easeIn(t * 2, 0, c, d) * 0.5 + b;
        }
        return Bounce.easeOut(t * 2 - d, 0, c, d) * 0.5 + c * 0.5 + b;
    },
};
const RequiredOptionKeys = ['duration', 'delay', 'ease', 'onUpdate', 'onComplete'];
export class SimpleTween {
    constructor(target, options) {
        this.progress = 0;
        this.startTime = 0;
        this.endTime = 0;
        this.elapsedTime = 0;
        this.vars = {};
        this.isPaused = false;
        this.isRunning = false;
        this.isCompleted = false;
        this.isKilled = false;
        this.duration = 1;
        this.delay = 0;
        this.onUpdate = (progress, tween) => { };
        this.onComplete = (tween) => { };
        this._id = `simpleTweenId${SimpleTween.tweenIndex++}`;
        this._options = options;
        const simpleTweenId = target.simpleTweenId || this._id;
        target.simpleTweenId = simpleTweenId;
        this._target = target;
        if (SimpleTween.animationId !== -1) {
            // loop active
            const waitingTween = SimpleTween.waitingTweens[simpleTweenId] || SimpleTween.tweens[simpleTweenId];
            if (waitingTween) {
                // already exists
                this.kill(false);
                if (waitingTween.applyOptions(options)) {
                    waitingTween.restart(33 / 1000);
                }
                else {
                    this._onComplete(false);
                }
                return waitingTween;
            }
            else {
                // 待機中または実行中のtweenがないので新規発行
                this._target.simpleTweenId = this._id;
                if (this.applyOptions(this._options)) {
                    this.start(33 / 1000);
                    SimpleTween.pushWaitingTween(this);
                }
                else {
                    this._onComplete(false);
                }
            }
        }
        else {
            // no loop
            this._target.simpleTweenId = this._id;
            if (this.applyOptions(this._options)) {
                this.start();
                SimpleTween.pushTween(this);
            }
            else {
                this._onComplete(false);
            }
        }
    }
    static update() {
        SimpleTween.animationId = window.requestAnimationFrame(SimpleTween.update);
        if (SimpleTween.numTweens === 0 && SimpleTween.numWaitingTweens === 0)
            SimpleTween.endLoop();
        for (let i = 0; i < SimpleTween.numTweens; i++) {
            SimpleTween.tweensArr[i].update();
        }
        for (let i = 0; i < SimpleTween.numWaitingTweens; i++) {
            const tween = SimpleTween.waitingTweensArr[i];
            const tweenId = tween.id;
            SimpleTween.pushTween(tween);
            SimpleTween.deleteWaitingTween(tweenId);
        }
    }
    static pushTween(tween) {
        SimpleTween.tweens[tween.id] = tween;
        SimpleTween.tweensArr = Object.values(SimpleTween.tweens);
        SimpleTween.numTweens = SimpleTween.tweensArr.length;
    }
    static deleteTween(tweenId) {
        delete SimpleTween.tweens[tweenId];
        SimpleTween.tweensArr = Object.values(SimpleTween.tweens);
        SimpleTween.numTweens = SimpleTween.tweensArr.length;
    }
    static pushWaitingTween(tween) {
        SimpleTween.waitingTweens[tween.id] = tween;
        SimpleTween.waitingTweensArr = Object.values(SimpleTween.waitingTweens);
        SimpleTween.numWaitingTweens = SimpleTween.waitingTweensArr.length;
    }
    static deleteWaitingTween(tweenId) {
        delete SimpleTween.waitingTweens[tweenId];
        SimpleTween.waitingTweensArr = Object.values(SimpleTween.waitingTweens);
        SimpleTween.numWaitingTweens = SimpleTween.waitingTweensArr.length;
    }
    static startLoop() {
        // console.log('startLoop')
        if (this.animationId !== -1)
            return;
        this.animationId = window.requestAnimationFrame(this.update);
    }
    static endLoop() {
        // console.log('endLoop')
        if (this.animationId !== -1)
            window.cancelAnimationFrame(this.animationId);
        this.animationId = -1;
        this.tweenIndex = 0;
    }
    get id() {
        return this._id;
    }
    get target() {
        return this._target;
    }
    get options() {
        return this._options;
    }
    applyOptions(options) {
        this._options = Object.assign({
            duration: this.duration,
            delay: this.delay,
            ease: Linear.easeNone,
            onUpdate: this.onUpdate,
            onComplete: this.onComplete,
        }, options);
        this.duration = this._options.duration * 1000;
        this.delay = this._options.delay * 1000;
        this.ease = this._options.ease;
        this.onUpdate = this._options.onUpdate;
        this.onComplete = this._options.onComplete;
        let needToStart = false;
        for (const key in this._options) {
            if (!RequiredOptionKeys.includes(key) && key.indexOf('simpleTweenId') === -1) {
                const from = this._target[key];
                const to = this._options[key];
                const sub = to - from;
                if (Math.abs(sub) > 1e-10) {
                    needToStart = true;
                }
                this.vars[key] = { from, to, sub };
            }
        }
        return needToStart;
    }
    update() {
        if (this.isCompleted || this.isPaused || this.isKilled) {
            return;
        }
        const currentTime = Date.now();
        this.elapsedTime = currentTime - this.startTime - this.delay;
        let t = Math.max(0, Math.min(1, this.elapsedTime / this.duration));
        t = Math.round(t * 1000000) / 1000000;
        this.progress = this.ease(t);
        // update vars
        for (const key in this.vars) {
            const vars = this.vars[key];
            this._target[key] = vars.from + vars.sub * this.progress;
        }
        this.onUpdate(this.progress, this);
        if (t === 1)
            this._onComplete();
    }
    _onComplete(doOnComplete = true) {
        this.isRunning = false;
        this.isCompleted = true;
        this.isPaused = false;
        SimpleTween.deleteTween(this._id);
        if (doOnComplete) {
            // console.log('onComplete')
            this.onComplete(this);
        }
        this.kill();
    }
    restart(elapsedTime = 0) {
        this.isCompleted = false;
        this.startTime = Date.now();
        this.elapsedTime = elapsedTime * 1000;
        this.endTime = this.startTime + this.duration;
        this.start();
    }
    start(elapsedTime = 0) {
        if (this.isKilled || this.isCompleted)
            return;
        // if (this.isKilled) {
        //   console.log('this tween has been killed')
        //   return
        // }
        // if (this.isCompleted) {
        //   console.log('this tween has been completed')
        //   return
        // }
        this.isRunning = true;
        this.isCompleted = false;
        this.isPaused = false;
        this.elapsedTime += elapsedTime * 1000;
        this.startTime = Date.now() - this.elapsedTime;
        this.endTime = this.startTime + this.duration;
        SimpleTween.startLoop();
    }
    resume() {
        this.start();
    }
    pause() {
        this.isRunning = true;
        this.isPaused = true;
    }
    kill(deleteTargetId = true) {
        if (this.isKilled)
            return;
        this.isKilled = true;
        this.isRunning = false;
        this.isCompleted = true;
        this.isPaused = false;
        if (deleteTargetId)
            delete this._target.simpleTweenId;
        delete this._target;
        delete this._options;
        this.onComplete = () => { };
        this.onUpdate = () => { };
        if (SimpleTween.waitingTweens[this._id])
            SimpleTween.deleteTween[this._id];
        if (SimpleTween.tweens[this._id])
            SimpleTween.deleteWaitingTween[this._id];
    }
}
SimpleTween.animationId = -1;
SimpleTween.tweenIndex = 0;
SimpleTween.tweens = {};
SimpleTween.tweensArr = [];
SimpleTween.numTweens = 0;
SimpleTween.waitingTweens = {};
SimpleTween.waitingTweensArr = [];
SimpleTween.numWaitingTweens = 0;
