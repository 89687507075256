import { gsap, Linear } from 'gsap';
import $, { event } from 'jquery';

export const leaveAnimation = async (data) => {
  const container = data.current.container;
  return new Promise((resolve) => {
    gsap.to(container, {
      duration: 0.4,
      opacity: 0,
      overwrite: true,
      ease: Linear.easeNone,
      onComplete: () => {
        container.style.top = '0';
        container.style.left = '0';
        container.style.position = 'absolute';
        console.log('complete leave animation');
        resolve();
      }
    });
  });
};

export const enterAnimation = async (data) => {
  const container = data.next.container;
  container.style.top = '';
  container.style.left = '';
  container.style.position = '';
  return new Promise((resolve) => {
    gsap.from(container, {
      duration: 0.4,
      opacity: 0,
      overwrite: true,
      ease: Linear.easeNone,
      onComplete: () => {
        console.log('complete enter animation');
        resolve();
      }
    });
  });
};

//
export const topEvent = () => {
  $('a[href^="/#about"]').attr('href', '#about');
  $('a[href^="/#loadmap"]').attr('href', '#roadmap');
};

export const underlayerEvent = () => {
  $('a[href^="#about"]').attr('href', '/#about');
  $('a[href^="#loadmap"]').attr('href', '/#roadmap');
};
