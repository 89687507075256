var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Vector2, PlaneGeometry } from 'three';
import IsometricGLTemplate from './IsometricGLTemplate';
import KVScene from './KVScene';
import kvScenesData from './data/data';
import { detectWebpSupported } from '../utils/img';
import KVBgImage from './KVBgImage';
import { BreakPoint, KVTextureSize, KV_MAIN_IMAGE_NOISE_TEXTURE, KV_TEXTURE_SIZE_DEFAULT } from '../constants';
import WebGLBase from './WebGLBase';
import KVBgGroupPlane from './KVBgGroupPlane';
import { wait } from '../utils/timer';
export const COMMON_PLANE_GEOMETRY_SIZE = 100;
export default class KVGL extends IsometricGLTemplate {
    constructor(container, canvas = null, countUpLoaded) {
        super(container, canvas);
        this.currentIndex = 0;
        this.nextIndex = 1;
        this.imageSrcKey = 'src';
        this.textureSize = '';
        this.layout = '';
        this.breakPoint = '';
        this.maxTextureSize = Infinity;
        this.numScenes = kvScenesData.length;
        this.pointerPos = new Vector2();
        this.trailerPos = new Vector2();
        this.beforePointerPos = new Vector2();
        this.kvScenes = [];
        this.bgParams = {
            width: 0,
            height: 0,
            offsetY: 0,
            uvOffset: new Vector2(),
            uvSize: new Vector2(1, 1),
            cornerRadius: 0,
            containerWidth: 0,
            containerHeight: 0
        };
        this.detectWebpSupportedPromise = detectWebpSupported();
        this.countUpLoaded = countUpLoaded;
    }
    initContents() {
        return __awaiter(this, void 0, void 0, function* () {
            const scene = this.scene;
            const isWebpSupported = yield this.detectWebpSupportedPromise;
            if (isWebpSupported)
                this.imageSrcKey = 'webpSrc';
            console.log({ isWebpSupported });
            // mainImageNoiseTexture
            const mainImageNoiseTexture = yield WebGLBase.initImgTexture(KV_MAIN_IMAGE_NOISE_TEXTURE);
            this.countUpLoaded(); //テクスチャロード分
            // this.downPixelRatioThresholdPixels = this.isFirefox ? 3000000 : 7500000
            this.downPixelRatioThresholdPixels = 7500000;
            // this.maxPixelRatio = 2
            const commonPlaneGeometry = new PlaneGeometry(COMMON_PLANE_GEOMETRY_SIZE, COMMON_PLANE_GEOMETRY_SIZE);
            // bg
            this.bgImage = new KVBgImage(commonPlaneGeometry);
            scene.add(this.bgImage);
            // bgGroupPlane
            this.bgGroupPlane = new KVBgGroupPlane(commonPlaneGeometry);
            scene.add(this.bgGroupPlane);
            // maxTextureSize
            const renderer = this.renderer;
            const glContext = renderer.getContext();
            this.maxTextureSize = glContext.getParameter(glContext.MAX_TEXTURE_SIZE);
            console.log({ maxTextureSize: this.maxTextureSize });
            // kvScenes
            for (const kvSceneData of kvScenesData) {
                const kvScene = new KVScene(kvSceneData, commonPlaneGeometry, mainImageNoiseTexture, this.countUpLoaded);
                this.kvScenes.push(kvScene);
                kvScene.setBgGroupTexture(this.bgGroupPlane.getRenderTargetTexture());
                scene.add(kvScene);
            }
            return this.loadTextures();
        });
    }
    loadTextures() {
        return __awaiter(this, void 0, void 0, function* () {
            // テクスチャのサイズを決定
            this.updateTextureSize();
            const promises = [];
            // テクスチャのロードはKVSceneに委ねる
            for (const kvScene of this.kvScenes) {
                promises.push(kvScene.loadTextures(this.textureSize, this.imageSrcKey));
            }
            return Promise.all(promises);
        });
    }
    setBgImageTextures() {
        const kvScene0Data = kvScenesData[this.currentIndex];
        const kvScene1Data = kvScenesData[this.nextIndex];
        this.bgImage.resetAnimationValue();
        this.bgImage.setTexture(0, kvScene0Data.textureData.bg[this.textureSize].texture);
        this.bgImage.setTexture(1, kvScene1Data.textureData.bg[this.textureSize].texture);
    }
    swapScenes(currentIndex, nextIndex, onShowNextScene = () => { }, onHideGlitchImages = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadTextures();
            this.setBgImageTextures();
            this.bgImage.swapImages(0.6);
            const _kvScene = this.kvScenes[this.currentIndex];
            _kvScene.hide().then(() => {
                _kvScene.setVisible(false, false);
                _kvScene.reset();
            });
            yield wait(0.4);
            this.currentIndex = currentIndex;
            this.nextIndex = nextIndex;
            wait(0.8).then(() => {
                onShowNextScene();
            });
            const kvScene = this.kvScenes[this.currentIndex];
            return kvScene.show(0, 2.6, () => { }, onHideGlitchImages);
        });
    }
    setPointerPos(pointerPos) {
        this.pointerPos = pointerPos;
        for (const kvScene of this.kvScenes) {
            kvScene.setPointerPos(pointerPos);
        }
        this.bgImage.setPointerPos(pointerPos);
    }
    setTrailerPos(trailerPos) {
        this.trailerPos = trailerPos;
        for (const kvScene of this.kvScenes) {
            kvScene.setTrailerPos(trailerPos);
        }
    }
    beforeRenderContents() {
        // clear
        const renderer = this.renderer;
        for (const kvScene of this.kvScenes) {
            kvScene.update(this.time);
        }
        this.bgImage.update(this.time);
        // renderTo renderTarget for bgGroup
        this.bgGroupPlane.visible = false;
        this.bgImage.visible = true;
        this.kvScenes[this.currentIndex].setVisible(false, true);
        this.bgGroupPlane.render(renderer, this.scene, this.camera);
        this.bgGroupPlane.visible = true;
        this.kvScenes[this.currentIndex].setVisible(true, false);
        this.beforePointerPos = this.pointerPos;
    }
    onResizeContents() {
        var _a;
        this.adjustPixelDeviceRatio();
        for (const kvScene of this.kvScenes) {
            kvScene.setMainImageParams(this.width, this.height, this.pixelRatio);
            kvScene.setGlitchImagesParams(this.bgParams, this.width, this.height);
        }
        (_a = this.bgImage) === null || _a === void 0 ? void 0 : _a.applyParams(this.bgParams);
        this.bgGroupPlane.setResolution(this.width, this.height, this.pixelRatio);
    }
    applyBgParams(bgParams) {
        this.bgParams = bgParams;
    }
    setTextureSize(textureSize) {
        this.textureSize = textureSize;
        for (const kvScene of this.kvScenes) {
            kvScene.setTextureSize(this.textureSize);
        }
    }
    setLayout(layout) {
        this.layout = layout;
        for (const kvScene of this.kvScenes) {
            kvScene.setLayout(this.layout);
        }
    }
    setBreakPoint(breakPoint) {
        this.breakPoint = breakPoint;
    }
    updateTextureSize() {
        this.textureSize = KVTextureSize.DEFAULT;
        if (this.breakPoint === BreakPoint.SMALL &&
            this.maxTextureSize < KV_TEXTURE_SIZE_DEFAULT) {
            this.textureSize = KVTextureSize.SMALL;
        }
    }
    play(delay = 0, onHideGlitchImages = () => { }) {
        this.start();
        const kvScene0Data = kvScenesData[this.numScenes - 1];
        const kvScene1Data = kvScenesData[0];
        this.bgImage.setTexture(0, kvScene0Data.textureData.bg[this.textureSize].texture);
        this.bgImage.setTexture(1, kvScene1Data.textureData.bg[this.textureSize].texture);
        this.kvScenes[this.currentIndex].show(delay, 2.6, () => { }, onHideGlitchImages);
        return this.bgImage.play(delay + 0.4);
    }
    resume() {
        this.start();
    }
    pause() {
        this.stop();
    }
    reset() {
        var _a;
        const currentScene = this.kvScenes[this.currentIndex];
        currentScene.setVisible(false, false);
        this.currentIndex = 0;
        this.nextIndex = 0;
        for (const kvScene of this.kvScenes) {
            kvScene.reset();
        }
        this.bgImage.reset();
        if ((_a = this.canvas) === null || _a === void 0 ? void 0 : _a.parentElement)
            this.container.removeChild(this.canvas);
    }
    setContainer(container) {
        this.container = container;
        this.container.appendChild(this.canvas);
    }
    initDebugPanel(pane) {
        const folder = pane.addFolder({ title: 'KV' });
        this.bgImage.initDebugPanel(folder);
        for (const kvScene of this.kvScenes) {
            kvScene.initDebugPanel(folder);
        }
    }
}
